import { useTranslation } from 'react-i18next';

export const FormRequired = () => {
  const { t } = useTranslation('app', { keyPrefix: 'forms' });

  return (
    <span className="flex w-full justify-end text-xs leading-5 text-neutral-700">
      {['*', t('required')].join(' ')}
    </span>
  );
};
