import { AppData } from './types';

export const initialState: AppData = {
  id: '',
  tenant: {},
  firstName: '',
  lastName: '',
  role: undefined,
  isLogged: false,
  isManager: false,
  isForeman: false,
  isCadTechnician: false,
  apiActivity: true,
  onDeckError: false,
  hotSeatError: false,
  isTutorial: false,
  isDropdownEnabled: false,
  admin: {
    isLogged: false,
    data: {},
  },
};
