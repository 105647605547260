import { tenantsRoute as route } from '@utils/consts';
import { ProtectedRoute } from '@components/protectedRoute';

const routes = [
  {
    path: route.index,
    lazy: async () => {
      const { TenantsProvider } = await import('@contexts/panel/tenants');
      const { TenantsRoot } = await import('@components/panel/tenants');
      return {
        Component: () => (
          <ProtectedRoute panel>
            <TenantsProvider>
              <TenantsRoot />
            </TenantsProvider>
          </ProtectedRoute>
        ),
      };
    },
    children: [
      {
        path: '',
        lazy: async () => {
          const { Tenants } = await import('@pages/panel/tenants');
          return {
            Component: () => <Tenants />,
          };
        },
      },
      {
        path: route.tenant(),
        children: [
          {
            path: '',
            lazy: async () => {
              const { Tenant } = await import('@pages/panel/tenants/:id');
              return {
                Component: () => <Tenant />,
              };
            },
          },
          {
            path: route.workflow(),
            lazy: async () => {
              const { WorkflowsProvider } = await import('@contexts/panel/tenants/workflows');
              const { WorkflowsRoot } = await import('@components/panel/tenants/workflows');
              return {
                Component: () => (
                  <WorkflowsProvider>
                    <WorkflowsRoot />
                  </WorkflowsProvider>
                ),
              };
            },
            children: [
              {
                path: route.workProgress,
                lazy: async () => {
                  const { WorkflowWorkProgress } = await import(
                    '@pages/panel/tenants/:id/workflow/:id/workProgress'
                  );
                  return {
                    Component: () => <WorkflowWorkProgress />,
                  };
                },
              },
              {
                path: route.materials,
                lazy: async () => {
                  const { WorkflowMaterials } = await import(
                    '@pages/panel/tenants/:id/workflow/:id/materials'
                  );
                  return {
                    Component: () => <WorkflowMaterials />,
                  };
                },
              },
              {
                path: route.questionnaire(),
                lazy: async () => {
                  const { QuestionnaireProvider } = await import(
                    '@contexts/panel/tenants/workflows/questionnaire'
                  );
                  const { WorkflowQuestionnaire } = await import(
                    '@pages/panel/tenants/:id/workflow/:id/questionnaires/:id'
                  );
                  return {
                    Component: () => (
                      <QuestionnaireProvider>
                        <WorkflowQuestionnaire />
                      </QuestionnaireProvider>
                    ),
                  };
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

export { routes as tenantsRoutes };
