/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { ParseTo, Speciality, SpecialtyRecord } from '@root/globalTypes';

export const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

export const capitalizeFirstLetters = (text: string) =>
  text
    .split(' ')
    .map((el) => capitalizeFirstLetter(el))
    .join(' ');

export const generateElemId = (text: string) => text.split(' ').join('_');

export const snakeToCamelCase = (text: string) =>
  text.includes('_')
    ? text
        .split('_')
        .map((part: string, i: number) => (i === 0 ? part : capitalizeFirstLetter(part)))
        .join('')
    : text;

export const camelToSnakeCase = (text: string) =>
  text
    .split('')
    .map((letter: string) => {
      if (letter === '_' || letter === '-' || !isNaN(parseInt(letter))) return letter;
      return letter === letter.toUpperCase() ? `_${letter.toLowerCase()}` : letter;
    })
    .join('');

export const kebabToCamelCase = (text: string) => text.replace(/-./g, (el) => el[1].toUpperCase());

export const humanizeText = (text: string) =>
  capitalizeFirstLetter(camelToSnakeCase(text).split('_').join(' '));

export const customJsonParser = <T>(data: T, keyFormatter: (arg0: string) => string): T => {
  if (typeof keyFormatter !== 'function') return data;
  if (typeof data !== 'object') return data;
  return JSON.parse(JSON.stringify(data), function (key, value) {
    const reg = /^\d+$/;
    if (reg.test(key)) return value;
    const newKey = keyFormatter(key);
    if (key !== newKey) {
      // eslint-disable-next-line immutable/no-mutation, immutable/no-this
      this[newKey] = value;
      return;
    }
    return value;
  });
};

// simplify usage by single export methods
export const formatBody = <T>(body: T): T => {
  if (!body) return body;
  if (typeof body !== 'object') return body;
  return customJsonParser(body, camelToSnakeCase);
};
export const formatResponse = <T>(body: T): T => {
  if (!body) return body;
  if (typeof body !== 'object') return body;
  return customJsonParser(body, snakeToCamelCase);
};

export const loginRedirect = (pathname: string) => {
  return `?redirect=${pathname}`;
};

export const getInitials = (text: string, index: number = 1) => text.slice(0, index).toUpperCase();

export const isTextTruncated = (element: HTMLElement) =>
  element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;

export const parse = (value: string, to: ParseTo) => {
  const fn = to === 'camel' ? snakeToCamelCase : camelToSnakeCase;
  return fn(value);
};

export const parseSpecialtiesAttributes = <T>(data: T[], parseTo: ParseTo) => {
  const items = data as SpecialtyRecord[];
  return items.map((el) => ({
    ...el,
    name: el?.name ? parse(el.name, parseTo) : '',
  })) as T[];
};

export const parseSpecialties = (data: Speciality[], parseTo: ParseTo) =>
  data.map((el) => (el ? parse(el, parseTo) : ''));

export const roundPercentage = (value: number) => Math.round((value + Number.EPSILON) * 100) / 100;

export const parseNegativeValue = (value: number) => Math.max(0, value);
