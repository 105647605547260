import { SvgConsumer } from '@components/icons/svgConsumer';
import { Button } from '../button';
import { Trans, useTranslation } from 'react-i18next';
import { useExitImpersonateUser } from '@services/users/impersonate/exit';
import { useAppContext } from '@contexts/app';
import { snakeToCamelCase } from '@utils/textHelpers';
import { appRoutes } from '@utils/consts';
import { parseRoute } from '@utils/i18n';
import { localStore } from '@utils/localStore';

export const ImpersonateBanner = () => {
  const { firstName, lastName, tenant, role, isLogged, admin } = useAppContext();

  const { t } = useTranslation('app');

  const active = isLogged && admin.isLogged;

  const { mutate } = useExitImpersonateUser({
    callback: () => {
      localStore.removeItem('app-user');
      localStore.removeItem('app-impersonate');
      // eslint-disable-next-line immutable/no-mutation
      window.location.href = `/${parseRoute(appRoutes.tenant(tenant.id))}`;
    },
  });

  if (!active) return null;

  return (
    <div className="bg-blue-300">
      <div className="container flex items-center justify-between px-4 py-1">
        <span className="flex h-14 items-center gap-5 text-sm text-white">
          <SvgConsumer className="shrink-0 text-heading-xs" id="impersonate" />
          <span>
            <Trans
              t={t}
              i18nKey="navigation.impersonate.title"
              values={{
                company: tenant.companyName,
                user: [firstName, lastName].join(' '),
                role: t(['role', snakeToCamelCase(role || '')].join('.')),
              }}
              components={{ bold: <strong /> }}
            />
          </span>
        </span>
        <Button onClick={() => mutate()} size="sm" variant="secondary">
          <SvgConsumer className="shrink-0" id="signIn" />
          <span>{t('navigation.impersonate.action')}</span>
        </Button>
      </div>
    </div>
  );
};
