import { appRoutes } from '@utils/consts';

export const isPanel = () => window.location.pathname.includes(appRoutes.panel);

export const usePanel = () => {
  const panel = isPanel();

  const requestUrl = panel ? 'admins' : 'users';

  const parseUrl = (url: string) => {
    if (url === appRoutes.index) return panel ? appRoutes.panel : '';
    return [panel && appRoutes.panel, url].filter(Boolean).join('/');
  };

  return { panel, parseUrl, requestUrl };
};
