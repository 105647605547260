import { useAppContext } from '@contexts/app';
import { SignIn } from './signIn';
import { appRoutes } from '@utils/consts';
import { parseRoute } from '@utils/i18n';
import { localStore } from '@utils/localStore';
import { PageLoader } from '@components/page/loader';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getSlug, removeSlug } from '@hooks/slug/helpers';
import { useLogout } from '@hooks/useLogout';
import { useQueryParams } from '@hooks/queryParams';

export const DefaultView = () => {
  const {
    isLogged,
    tenant,
    admin: { isLogged: isAdminLogged },
    isForeman,
    isManager,
    isCadTechnician,
  } = useAppContext();

  const navigate = useNavigate();
  const {
    params: { auth, isLogout },
    setParams,
  } = useQueryParams();
  const slug = getSlug() || tenant.slug;
  const logout = useLogout();

  const admin = isAdminLogged || localStore.getItem('app-admin');
  const user = isLogged || localStore.getItem('app-user');

  useEffect(() => {
    if (!slug && user && !isForeman) {
      if (isLogout) {
        logout('user');
        return;
      }
      setParams({ auth: String(localStore.getItem('app-user')) }, { disableSaveFilters: true });
    }
    if (slug && !user && !auth) removeSlug();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, user, isForeman, auth, logout]);

  useEffect(() => {
    if (!user && admin) navigate(parseRoute(`/${appRoutes.panel}`));
    if (!user) return;
    if (isForeman) navigate(parseRoute(`/${appRoutes.foremanSchedules}`));
    if (isManager) navigate(parseRoute(`/${appRoutes.hotSeat}`));
    if (isCadTechnician) navigate(parseRoute(`/${appRoutes.jobs}`));
  }, [user, admin, isForeman, isManager, isCadTechnician, navigate]);

  if (!user && !admin) return <SignIn />;
  if (!user && admin) return <Navigate to={parseRoute(`/${appRoutes.panel}`)} replace />;
  if (isForeman) {
    <Navigate to={parseRoute(`/${appRoutes.foremanSchedules}`)} replace />;
    return;
  }
  if (isManager) return <Navigate to={parseRoute(`/${appRoutes.hotSeat}`)} replace />;
  if (isCadTechnician) return <Navigate to={parseRoute(`/${appRoutes.jobs}`)} replace />;
  return <PageLoader />;
};
