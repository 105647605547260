import { ApiError } from '@root/globalTypes';
import { queryFetch } from '../api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { useAppContext } from '@contexts/app';
import { useShowErrorToast } from '@utils/formHelpers';

export type CompleteSubtaskReq = {
  id: string;
};

export interface CompleteSubtaskRespSuccess {
  [key: string]: unknown;
  readonly status?: boolean;
}

export type CompleteSubtaskResp = CompleteSubtaskRespSuccess | ApiError;

export const useCompleteSubtask = ({
  callback,
}: {
  callback: (data: CompleteSubtaskResp) => void;
}) => {
  const queryClient = useQueryClient();
  const { showErrorToast } = useShowErrorToast();
  const { isForeman } = useAppContext();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ id }: CompleteSubtaskReq): Promise<CompleteSubtaskResp> =>
      queryFetch({ url: `subtasks/${id}/complete`, opt: { method: 'PUT' } }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (!success) return;
      await queryClient.refetchQueries({ queryKey: queryKeys.notifications });
      if (isForeman) await queryClient.refetchQueries({ queryKey: queryKeys.foremanTask });
      else {
        await queryClient.refetchQueries({ queryKey: queryKeys.jobStages });
        await queryClient.refetchQueries({ queryKey: queryKeys.jobs });
      }
    },
    onSettled: (data) => {
      const { success, errors } = data as CompleteSubtaskResp;
      if (errors) showErrorToast(errors as string);
      if (success) callback(data as CompleteSubtaskResp);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
