import { appRoutes, foremanRoute } from '@utils/consts';
import { Outlet } from 'react-router-dom';

export const foremanSharedRoutes = {
  path: foremanRoute.taskId,
  lazy: async () => {
    const { ForemanTaskProvider } = await import('@contexts/foreman/task');
    const { ForemanRouteProvider } = await import('@contexts/foreman/route');
    return {
      Component: () => (
        <ForemanTaskProvider>
          <ForemanRouteProvider>
            <Outlet />
          </ForemanRouteProvider>
        </ForemanTaskProvider>
      ),
    };
  },
  children: [
    {
      path: appRoutes.base,
      lazy: async () => {
        const { ForemanTask } = await import('@pages/foreman/shared/task');
        return {
          Component: () => <ForemanTask />,
        };
      },
    },
    {
      path: foremanRoute.form(foremanRoute.pointId),
      lazy: async () => {
        const { ForemanForm } = await import('@pages/foreman/shared/form');
        return {
          Component: () => <ForemanForm />,
        };
      },
    },
    {
      path: foremanRoute.taskQuestionnaire,
      lazy: async () => {
        const { QuestionaryProvider } = await import('@contexts/questionary');
        return {
          Component: () => (
            <QuestionaryProvider>
              <Outlet />
            </QuestionaryProvider>
          ),
        };
      },
      children: [
        {
          path: appRoutes.base,
          lazy: async () => {
            const { ForemanQuestionary } = await import('@pages/foreman/shared/questionary');
            return {
              Component: () => <ForemanQuestionary />,
            };
          },
        },
        {
          path: foremanRoute.questionnaireQuestion,
          lazy: async () => {
            const { ForemanQuestion } = await import('@pages/foreman/shared/questionary/:id');
            return {
              Component: () => <ForemanQuestion />,
            };
          },
        },
      ],
    },
  ],
};
