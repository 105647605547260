import { camelToSnakeCase } from './textHelpers';
import { UserRole } from '@root/globalTypes';
import { WorkloadView } from '@components/workload/filters/types';

export enum ESupportedLangs {
  en = 'en',
  es = 'es',
}

export enum Priorities {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export type JobStageStatus =
  | 'toSchedule'
  | 'scheduleRequestSent'
  | 'scheduled'
  | 'inProgress'
  | 'completed'
  | 'warning'
  | 'error';

export const priorities = Object.values(Priorities);

export const supportedLanguages: string[] = Object.values(ESupportedLangs);

export const getLangFromPath = () => {
  const p = window.location.pathname;
  const isSupportedLang = supportedLanguages.find((lang) => p.startsWith(`/${lang}`));
  if (isSupportedLang) return isSupportedLang;
  return 'en';
};

export const jobIdRoute = {
  index: '',
  materials: 'materials',
  inventory: 'inventory',
  documents: 'documents',
  questionnaires: 'questionnaires',
  questionnaire: 'questionnaires/:questionaryId',
  workProgress: 'work-progress',
  workProgressStage: ':stageId',
};

export const questionnairesRoute = {
  index: 'questionnaires',
  questionnaire: (id?: string) => id || ':questionaryId',
};

export const foremanRoute = {
  index: 'foreman',
  archive: 'archive',
  requests: 'requests',
  schedules: 'schedules',
  questionnaires: 'questionnaires',
  questionnaire: (questionaryId: string | undefined) =>
    [questionnairesRoute.index, questionnairesRoute.questionnaire(questionaryId)].join('/'),
  form: (pointId: string) => `form/${pointId}`,
  other: 'other',
  taskId: ':taskId',
  routeId: ':routeId',
  pointId: ':pointId',
  questionId: ':questionId',
  taskQuestionnaire: 'questionnaires/:questionaryId',
  questionnaireQuestion: 'questions/:questionId',
  questions: (questionId: string | undefined, params: string) =>
    [['questions', questionId].join('/'), params].join(''),
};

export const usersRoute = {
  index: 'users',
  acceptInvitation: 'accept-invitation',
};

export const panelRoute = {
  index: 'panel',
};

export const adminsRoute = {
  index: 'admins',
  acceptInvitation: 'accept-invitation',
};

export const tenantsRoute = {
  index: 'tenants',
  tenant: (id?: string) => id || ':tenantId',
  workflow: (id?: string) => ['workflow', id || ':workflowId'].join('/'),
  questionnaire: (id?: string) => ['questionnaires', id || ':questionnaireId'].join('/'),
  workProgress: 'work-progress',
  materials: 'materials',
};

export const buildersRoute = {
  index: 'builders',
  builder: (id?: string) => id || ':builderId',
};

export const appRoutes = {
  index: '/',
  base: '',
  back: '..',
  id: ':id',
  notFound: 'not-found',

  users: usersRoute.index,
  usersAcceptInvitation: [usersRoute.index, usersRoute.acceptInvitation].join('/'),

  builders: buildersRoute.index,
  builder: (id?: string) => [buildersRoute.index, buildersRoute.builder(id)].join('/'),

  panel: panelRoute.index,

  admins: [panelRoute.index, adminsRoute.index].join('/'),

  tenants: [panelRoute.index, tenantsRoute.index].join('/'),
  tenant: (id?: string) =>
    [panelRoute.index, tenantsRoute.index, tenantsRoute.tenant(id)].join('/'),
  tenantWorkflow: (tenantId?: string, workflowId?: string) =>
    [
      panelRoute.index,
      tenantsRoute.index,
      tenantsRoute.tenant(tenantId),
      tenantsRoute.workflow(workflowId),
    ].join('/'),
  tenantWorkflowMaterials: (tenantId?: string, workflowId?: string) =>
    [
      panelRoute.index,
      tenantsRoute.index,
      tenantsRoute.tenant(tenantId),
      tenantsRoute.workflow(workflowId),
      tenantsRoute.materials,
    ].join('/'),
  workflowQuestionnaire: (tenantId?: string, workflowId?: string, questionnaireId?: string) =>
    [
      panelRoute.index,
      tenantsRoute.index,
      tenantsRoute.tenant(tenantId),
      tenantsRoute.workflow(workflowId),
      tenantsRoute.questionnaire(questionnaireId),
    ].join('/'),
  tenantWorkflowWorkProgress: (tenantId?: string, workflowId?: string) =>
    [
      panelRoute.index,
      tenantsRoute.index,
      tenantsRoute.tenant(tenantId),
      tenantsRoute.workflow(workflowId),
      tenantsRoute.workProgress,
    ].join('/'),
  form: (id: string) => `form/${id}`,
  foremanArchive: `${foremanRoute.index}/${foremanRoute.archive}`,
  foremanRequests: `${foremanRoute.index}/${foremanRoute.requests}`,
  foremanSchedules: `${foremanRoute.index}/${foremanRoute.schedules}`,
  foremanOther: `${foremanRoute.index}/${foremanRoute.other}`,
  passwordRecovery: 'password-recovery',
  passwordRecoveryInfo: 'password-recovery/info',
  passwordReset: 'password-reset',
  passwordResetConfirmation: 'password-reset/confirmed',
  jobs: 'jobs',
  jobPreview: (id: string) => `preview/${id}`,
  job: (id: string) => `jobs/${id}`,
  jobMaterials: (id: string) => `jobs/${id}/${jobIdRoute.materials}`,
  jobInventory: (id: string) => `jobs/${id}/${jobIdRoute.inventory}`,
  jobDocuments: (id: string) => `jobs/${id}/${jobIdRoute.documents}`,
  jobQuestionnaires: (id: string) => `jobs/${id}/${questionnairesRoute.index}`,
  jobQuestionnaire: (id: string, questionaryId: string) =>
    [
      appRoutes.job(id),
      questionnairesRoute.index,
      questionnairesRoute.questionnaire(questionaryId),
    ].join('/'),
  jobWorkProgress: (id: string) => `jobs/${id}/${jobIdRoute.workProgress}`,
  hotSeat: 'hot-seat',
  onDeck: 'on-deck',
  workload: 'workload',
  companies: 'companies',
  company: (id: string) => `companies/${id}`,
  inventory: 'inventory',
  map: 'map',
};

const basicAccess = [
  appRoutes.hotSeat,
  appRoutes.workload,
  appRoutes.onDeck,
  appRoutes.map,
  appRoutes.jobs,
];

const extendedAccess = [...basicAccess, appRoutes.inventory];

export const userAccessMap: Record<string, string[]> = {
  superAdmin: [appRoutes.admins, appRoutes.tenants],
  administrator: [
    ...basicAccess,
    appRoutes.users,
    appRoutes.builders,
    appRoutes.companies,
    appRoutes.inventory,
  ],
  projectCoordinator: [
    ...basicAccess,
    appRoutes.builders,
    appRoutes.companies,
    appRoutes.inventory,
  ],
  cadTechnician: [appRoutes.map, appRoutes.jobs, appRoutes.inventory],
  superintendent: extendedAccess,
  foreman: [],
  layoutManager: extendedAccess,
  customerServiceManager: extendedAccess,
  generalSuperintendent: extendedAccess,
  readOnly: extendedAccess,
};

export const usersAccessList = (path: string) => {
  const allowedUsers: UserRole[] = [];
  Object.entries(userAccessMap).forEach(([user, values]) => {
    if (values.includes(path)) allowedUsers.push(camelToSnakeCase(user) as UserRole);
  });
  return allowedUsers;
};

export const queryKeys = {
  dropdowns: {
    additive: ['additive-dropdown'],
    builders: ['builders-dropdown'],
    builderSuperintendents: ['builder-superintendents-dropdown'],
    companies: ['companies-dropdown'],
    concreteMix: ['concrete-mix-dropdown'],
    inventoryItems: ['inventory-items-dropdown'],
    locations: ['locations-dropdown'],
    panel: {
      roles: ['roles-dropdown'],
      statuses: ['statuses-dropdown'],
      zones: ['zones-dropdown'],
    },
    pourElements: ['pour-elements-dropdown'],
    pourMethods: ['pour-methods-dropdown'],
    routes: ['routes-dropdown'],
    sites: ['sites-dropdown'],
    stages: ['stages-dropdown'],
    subtasksForFiles: ['subtasks-for-files-dropdown'],
    tasks: ['tasks-dropdown'],
    users: ['users-dropdown'],
  },

  builders: ['builders'],
  builder: ['builder'],

  users: ['users'],
  user: ['user'],

  admins: ['admins'],
  admin: ['admin'],

  tenants: ['tenants'],
  tenant: ['tenant'],
  tenantIntegration: ['tenant-integration'],

  workflows: {
    index: ['workflows'],
    item: ['workflow'],
    questionnaires: {
      index: ['workflow-questionnaires'],
      item: ['workflow-questionnaire'],
    },
    dropdowns: {
      conditions: {
        records: {
          related: ['workflow-conditions-related-records'],
          referable: ['workflow-conditions-referable-records'],
        },
        statuses: ['workflow-conditions-statuses'],
        types: ['workflow-conditions-types'],
        users: ['workflow-conditions-users'],
      },
      task: {
        confirmations: {
          index: ['workflows-task-confirmations'],
          date: ['workflows-task-date-confirmations'],
        },
        tasks: {
          dependent: ['workflows-task-dependent-tasks'],
          readyToSchedule: ['workflows-task-ready-to-schedule-tasks'],
        },
        types: {
          index: ['workflows-task-types'],
          user: ['workflows-task-user-types'],
          schedule: ['workflows-task-schedule-types'],
        },
      },
      subtask: {
        confirmations: {
          task: ['workflows-subtask-task-confirmations'],
        },
        tasks: { allocation: ['workflows-subtask-allocation-tasks'] },
        materials: ['workflows-subtask-materials'],
        confirmationDependentSubtasks: ['workflows-subtask-confirmation-dependent-subtasks'],
        types: {
          allocation: ['workflows-subtask-allocation-types'],
          allowedFile: ['workflows-subtask-allowed-file-types'],
        },
      },
    },
  },

  questionnaires: {
    index: ['questionnaires'],
    item: ['questionnaire'],
    sections: ['questionnaires-sections'],
    questions: ['questionnaires-sections-questions'],
    answers: ['questionnaires-sections-questions-answers'],
  },

  foremanSchedules: ['foreman-schedules'],
  foremanRoute: ['foreman-route'],
  foremanRequests: ['foreman-requests'],
  foremanArchive: ['foreman-archive'],
  foremanTask: ['foreman-task'],
  foremanStages: ['foreman-stages'],
  foremanOther: ['foreman-other'],
  plans: ['plans'],
  job: ['job'],
  jobs: ['jobs'],
  jobStages: ['job', 'stages'],
  jobStagesMenu: ['job-stages-menu'],
  jobInventory: ['job-inventory'],
  jobInventoryDialog: ['job-inventory-dialog'],
  jobConcreteDirect: ['job-concrete-direct'],
  jobPreview: ['job-preview'],
  onDeck: ['on-deck'],
  schedules: ['schedules'],
  superintendent: ['superintendent'],
  notifications: ['notifications'],
  documents: ['documents'],
  foremanDocuments: ['foreman-documents'],
  materials: ['materials'],
  foremanMaterials: ['foreman-materials'],
  workloadCalendar: ['workload-calendar'],
  workloadCalendarSidebar: ['workload-calendar-sidebar'],
  workload: ['workload'],
  filters: ['filters'],
  companies: ['companies'],
  company: ['company'],
  contacts: ['contacts'],
  files: ['files'],
  inventoryRoutes: ['inventory-routes'],
  inventoryRequested: ['inventory-requested'],
  inventoryRequestedLocation: ['inventory-requested-location'],
  inventoryAvailable: ['inventory-available'],
  inventoryAvailableLocation: ['inventory-available-location'],
  inventoryCustomAdd: ['inventory-custom-add'],
  inventoryMissingLoad: ['inventory-missing-load'],
  inventoryRemainingLoad: ['inventory-remaining-load'],
  locationItems: ['location-items'],
  map: ['map'],
  mapMenu: ['map-menu'],
  questionnaire: ['questionnaire'],
  questionnaireAttachment: ['questionnaire-attachment'],
};

export const speciality = {
  layout: 'Layout',
  footing: 'Footing',
  walls: 'Walls',
  pump: 'Pump',
  utilityDriver: 'Utility Driver',
  stripout: 'Stripout',
  crane: 'Crane',
  customerService: 'Customer Service',
};

export const multitaskRoles = ['crane', 'pump'];

export const uuidRegEx =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

export const geolocalizationRegEx =
  /^[-]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

export const slugRegEx = /^[a-z0-9]+(?:[_-][a-z0-9]+)*$/;

export const errorKeyRegEx = /^\w+$/;

export const isSecureConnection = window.location.protocol.includes('https');

export const foremanWidth = 160;

export const workloadViews: WorkloadView[] = ['inventory'];

export const use24h = import.meta.env.VITE_TIME_FORMAT === '24h';
