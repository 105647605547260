/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { Tab } from '@root/globalTypes';

import hotSeat from '@assets/tutorial/hotSeat.md';
import workload from '@assets/tutorial/workload.md';
import onDeck from '@assets/tutorial/onDeck.md';
import map from '@assets/tutorial/map.md';
import jobs from '@assets/tutorial/jobs.md';
import users from '@assets/tutorial/users.md';
import builders from '@assets/tutorial/builders.md';
import companies from '@assets/tutorial/companies.md';
import inventory from '@assets/tutorial/inventory.md';
import tenants from '@assets/tutorial/tenants.md';
import admins from '@assets/tutorial/admins.md';

export const data: Record<Tab, string> = {
  hotSeat,
  workload,
  onDeck,
  map,
  jobs,
  users,
  builders,
  companies,
  inventory,
  admins,
  tenants,
};
