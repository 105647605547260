import { adminsRoute as route } from '@utils/consts';
import { ProtectedRoute } from '@components/protectedRoute';

const routes = [
  {
    path: route.index,
    children: [
      {
        path: '',
        lazy: async () => {
          const { AdminsProvider } = await import('@contexts/panel/admins');
          const { AdminsRoot } = await import('@components/panel/admins');
          return {
            Component: () => (
              <ProtectedRoute panel>
                <AdminsProvider>
                  <AdminsRoot />
                </AdminsProvider>
              </ProtectedRoute>
            ),
          };
        },
        children: [
          {
            path: '',
            lazy: async () => {
              const { Admins } = await import('@pages/panel/admins');
              return {
                Component: () => <Admins />,
              };
            },
          },
        ],
      },
      {
        path: route.acceptInvitation,
        lazy: async () => {
          const { AcceptInvitation } = await import('@pages/users/acceptInvitation');
          return {
            Component: () => <AcceptInvitation />,
          };
        },
      },
    ],
  },
];

export { routes as adminsRoutes };
