import { Admin, DefaultApiResp, User } from '@root/globalTypes';
import { queryFetch } from '../api';
import { useMutation } from '@tanstack/react-query';
import { usePanel } from '@hooks/usePanel';

export interface SignInReq {
  email: string;
  password: string;
}

export interface SignInResp extends DefaultApiResp {
  data: Admin | User;
}

export type FormattedSignInData = { type: 'admin'; data: Admin } | { type: 'user'; data: User };

export interface FormattedSignInResp extends DefaultApiResp {
  data: FormattedSignInData;
}

export const useSignIn = ({ callback }: { callback: (data: FormattedSignInResp) => void }) => {
  const { panel, requestUrl } = usePanel();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ ...data }: SignInReq): Promise<SignInResp> =>
      queryFetch({
        url: [requestUrl, 'sign_in'].join('/'),
        data,
        opt: { method: 'POST' },
        auth: false,
        panel,
      }),
    onError: (err) => {
      console.error(err);
    },
    onSettled: (data) => {
      const values = panel
        ? { type: 'admin', data: data?.data }
        : { type: 'user', data: data?.data };
      callback({ ...data, data: values } as FormattedSignInResp);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
