import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryFetch } from '@services/api';
import { queryKeys } from '@utils/consts';
import { MaterialReq, MaterialResp } from '../types';

export const useForemanUpdateMaterial = ({
  callback,
}: {
  callback: (data: MaterialResp) => void;
}) => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: ({
      id,
      jobId,
      estimatedQuantity,
      ...material
    }: MaterialReq): Promise<MaterialResp> =>
      queryFetch({
        url: `foreman/materials/${id}`,
        data: { material },
        opt: { method: 'PUT' },
      }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (!success) return;
      await queryClient.refetchQueries({ queryKey: queryKeys.notifications });
      await queryClient.refetchQueries({ queryKey: queryKeys.foremanTask });
      await queryClient.refetchQueries({ queryKey: queryKeys.foremanDocuments });
      await queryClient.refetchQueries({ queryKey: queryKeys.foremanMaterials });
    },
    onSettled: (data) => {
      if (data) callback(data);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
