const getItem = (type: 'admin' | 'user') => {
  const param = new URLSearchParams(window.location.search).get('auth');
  if (type === 'user' && param) return JSON.stringify({ data: param });
  return localStorage.getItem(`app-${type}`);
};

export const getUserData = (
  type: 'admin' | 'user',
): {
  isLogged: boolean;
  id: string;
} => {
  const item = getItem(type);
  let parsed: Record<string, string> = {};

  if (typeof item === 'string') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = JSON.parse(item);
    if (typeof data === 'string') {
      parsed = JSON.parse(data) as Record<string, string>;
    }
  }

  return {
    isLogged: typeof item === 'string' ? item.includes('Access-Token') : false,
    id: parsed.id,
  };
};
