import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { capitalizeFirstLetter } from '@utils/textHelpers';
import { unparseRoute } from '@utils/i18n';
import { foremanRoute, panelRoute } from '@utils/consts';

export const useTitle = (dynamic?: string) => {
  const { pathname } = useLocation();
  const { id, tenantId, builderId } = useParams();

  const hasId = [id, tenantId, builderId].some(Boolean);
  const hasPrefix = [foremanRoute.index, panelRoute.index].some((el) =>
    pathname.includes(`${el}/`),
  );

  const parsedPathname = useMemo(
    () =>
      unparseRoute(pathname)
        .split('/')
        [hasPrefix ? 2 : 1].split('-')
        .map((el) => capitalizeFirstLetter(el))
        .join(' '),
    [pathname, hasPrefix],
  );

  const setTitle = useCallback(() => {
    if (!parsedPathname) return;
    // eslint-disable-next-line immutable/no-mutation
    document.title = `${dynamic && hasId ? `${dynamic} - ` : ''}${parsedPathname} - Retti`;
  }, [dynamic, hasId, parsedPathname]);

  useEffect(setTitle, [setTitle]);
};
