import { useMutation } from '@tanstack/react-query';
import { useShowErrorToast } from '@utils/formHelpers';
import { queryFetch } from '@services/api';
import { DefaultApiResp } from '@root/globalTypes';
import { useAppContext } from '@contexts/app';

export const useExitImpersonateUser = ({
  callback,
}: {
  callback: (data: DefaultApiResp) => void;
}) => {
  const { id } = useAppContext();

  const { showErrorToast } = useShowErrorToast();

  const { mutate, isPending } = useMutation({
    mutationFn: (): Promise<DefaultApiResp> =>
      queryFetch({
        url: ['users', id, 'exit_impersonate'].join('/'),
        opt: { method: 'PUT' },
      }),
    onError: (err) => {
      console.error(err);
    },
    onSettled: (data) => {
      const { success, errors } = data as DefaultApiResp;
      if (errors) showErrorToast(errors);
      if (success) callback(data as DefaultApiResp);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
