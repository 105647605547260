/* eslint-disable immutable/no-mutation */

import { EXCLUDED_SUBDOMAINS } from './constants';

const hostname = window.location.hostname.replace('www.', '');

export const getSlug = () => {
  const [subdomain] = hostname.split('.');
  if (EXCLUDED_SUBDOMAINS.includes(subdomain)) return '';
  return subdomain.toLocaleLowerCase();
};

export const updateSlug = (value: string) => {
  const currentSlug = getSlug();
  window.location.hostname = [
    value.toLocaleLowerCase(),
    currentSlug ? hostname.replace(`${currentSlug}.`, '') : hostname,
  ].join('.');
};

export const removeSlug = () => {
  const slug = getSlug();
  if (!slug) return;
  const newHostname = hostname.replace(`${slug}.`, '');
  if (newHostname === hostname) return;
  window.location.hostname = newHostname;
};

export const isSlugCorrect = (value: string) => {
  const currentSlug = getSlug();
  if (!currentSlug || currentSlug !== value.toLocaleLowerCase()) return false;
  return true;
};
