import { createContext, useContext, useEffect, useState } from 'react';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { AppContextData, AppData, AppProviderProps } from './types';
import { initialState } from './constants';
import { getUserData } from '@utils/getUserData';
import { modelUserData } from '@utils/userData';
import { useGetAdmin } from '@services/panel/admins/get';
import { useGetUser } from '@services/users/get';
import { localStore } from '@utils/localStore';
import { parseRoute } from '@utils/i18n';
import { appRoutes } from '@utils/consts';

const AppContext = createContext<AppContextData | Record<string, never>>({});

export const AppProvider = ({ children }: AppProviderProps) => {
  const admin = getUserData('admin');
  const user = getUserData('user');

  const { data: adminData } = useGetAdmin(admin.id);
  const { data: userData } = useGetUser(user.id);

  const [data, setData] = useState<AppData>(initialState);

  const {
    id,
    admin: {
      data: { id: adminId },
    },
  } = data;

  const fetching = useIsFetching();
  const mutating = useIsMutating();

  useEffect(() => {
    if (!userData?.data) return;
    const values = userData.data;
    if (user.id && id && user.id !== id) {
      localStore.removeItem('app-user');
      // eslint-disable-next-line immutable/no-mutation
      window.location.pathname = parseRoute(appRoutes.index);
    }
    if (values.id === id) return;

    setData((prev) => ({
      ...prev,
      ...modelUserData(values),
      isLogged: true,
      isDropdownEnabled: values.role !== 'foreman',
      onDeckError: userData.onDeckAlerts,
      hotSeatError: userData.hotSeatAlerts,
    }));
  }, [id, user.id, userData]);

  useEffect(() => {
    if (!adminData?.data) return;
    const values = adminData.data;
    if (values.id === adminId) return;

    setData((prev) => ({
      ...prev,
      admin: {
        data: values,
        isLogged: true,
      },
    }));
  }, [adminId, adminData?.data]);

  return (
    <AppContext.Provider
      value={{
        ...data,
        setData,
        apiActivity: Boolean(fetching) || Boolean(mutating),
      }}>
      {children}
    </AppContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAppContext = () => useContext(AppContext);
