import { ApiError } from '@root/globalTypes';
import { queryFetch } from '../api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { useShowErrorToast } from '@utils/formHelpers';

export type CompleteTaskReq = {
  id: string;
};

export interface CompleteTaskRespSuccess {
  [key: string]: unknown;
  readonly status?: boolean;
}

export type CompleteTaskResp = CompleteTaskRespSuccess | ApiError;

export const useCompleteTask = ({ callback }: { callback: (data: CompleteTaskResp) => void }) => {
  const queryClient = useQueryClient();
  const { showErrorToast } = useShowErrorToast();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ id, ...data }: CompleteTaskReq): Promise<CompleteTaskResp> =>
      queryFetch({ url: `tasks/${id}/complete`, data, opt: { method: 'PUT' } }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (!success) return;
      await queryClient.refetchQueries({ queryKey: queryKeys.jobStages });
      await queryClient.refetchQueries({ queryKey: queryKeys.jobs });
      await queryClient.refetchQueries({ queryKey: queryKeys.notifications });
      await queryClient.refetchQueries({ queryKey: queryKeys.onDeck });
    },
    onSettled: (data) => {
      const { success, errors } = data as CompleteTaskResp;
      if (errors) showErrorToast(errors as string);
      if (success) callback(data as CompleteTaskResp);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
