import { defaultCountries as countries, usePhoneInput } from 'react-international-phone';
import { PhoneProps } from './types';

export const Phone = ({ value }: PhoneProps) => {
  const { inputValue } = usePhoneInput({
    defaultCountry: 'us',
    value: value || '',
    countries,
  });

  if (!value) return null;
  return (
    <a
      onClick={(e) => e.stopPropagation()}
      href={`tel: ${inputValue}`}
      className="whitespace-nowrap text-left hover:underline">
      {inputValue}
    </a>
  );
};
