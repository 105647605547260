import { Button, ButtonProps } from '@components/ui/button';
import { cn } from '@utils/cn';

export const DashboardItemActionButton = ({ className, onClick, ...props }: ButtonProps) => (
  <Button
    variant="ghost"
    onClick={(e) => {
      e.stopPropagation();
      if (onClick) onClick(e);
    }}
    className={cn('h-fit p-1.5 text-m text-neutral-400 hover:text-neutral-700', className)}
    {...props}
  />
);
