/* eslint-disable react-refresh/only-export-components */

import { createBrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';
import { Layout } from '@components/layout';
import { appRoutes } from '@utils/consts';
import { DefaultView } from '@pages/defaultView';
import { LangValidation } from '@components/langValidation/langValidation';
import { useFilters } from '@hooks/useFilters';
import { useTitle } from '@hooks/useTitle';
import { Loader } from '@components/loader';
import { signUpRoutes } from '@pages/signIn/routes';
import { jobsRoutes } from '@pages/jobs/routes';
import { usersRoutes } from '@pages/users/routes';
import { buildersRoutes } from '@pages/builders/routes';
import { companiesRoutes } from '@pages/companies/routes';
import { inventoryRoutes } from '@pages/inventory_new/routes';
import { foremanRoutes } from '@pages/foreman/routes';
import { NotFound } from '@pages/notFound';
import { ErrorBoundary } from '@components/errorBoundary';
import { mapRoutes } from '@pages/map/routes';
import { hotSeatRoutes } from '@pages/hotSeat/routes';
import { workloadRoutes } from '@pages/workload/routes';
import { onDeckRoutes } from '@pages/onDeck/routes';
import { panelRoutes } from '@pages/panel/routes';
import { useSlug } from '@hooks/slug';

const MainRoute = () => {
  useFilters();
  useTitle();
  useSlug();

  return (
    <ErrorBoundary>
      <LangValidation>
        <Suspense fallback={<Loader />}>
          <Layout />
        </Suspense>
      </LangValidation>
    </ErrorBoundary>
  );
};

export const router = createBrowserRouter([
  {
    path: '/:lang?',
    element: <MainRoute />,
    children: [
      {
        path: appRoutes.base,
        element: <DefaultView />,
      },
      {
        path: appRoutes.notFound,
        element: <NotFound />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
      ...signUpRoutes,
      ...hotSeatRoutes,
      ...workloadRoutes,
      ...onDeckRoutes,
      ...mapRoutes,
      ...jobsRoutes,
      ...usersRoutes,
      ...buildersRoutes,
      ...companiesRoutes,
      ...inventoryRoutes,
      ...panelRoutes,
      ...foremanRoutes,
    ],
  },
]);
