export const icons = {
  blueprint: (
    <symbol id="blueprintIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 23">
      <path
        fill="currentColor"
        d="M21.6 2.9H7.1v-.2c0-1.2-1-2.2-2.2-2.2H3.1C1.6.5.3 1.8.3 3.3V20c0 1.5 1.2 2.8 2.8 2.8h18.5c1.2 0 2.2-1 2.2-2.2V5.1c0-1.2-1-2.2-2.2-2.2Zm-19.3.4c0-.4.4-.8.8-.8h1.8c.1 0 .2 0 .2.2V17c0 .1 0 .2-.2.2H3.1c-.3 0-.5 0-.8.1v-14Zm19.5 17.3c0 .1 0 .2-.2.2H3.1c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h1.8c1.2 0 2.2-1 2.2-2.2v-2.6h2.8V17c0 1.2 1 2.2 2.2 2.2h1.2c.6 0 1-.4 1-1s-.4-1-1-1h-1.2c-.1 0-.2 0-.2-.2v-6c0-.6-.4-1-1-1s-1 .4-1 1v1.4H7.1v-4h6.4v1.4c0 .6.4 1 1 1s1-.4 1-1V8.4h2.6c.1 0 .2 0 .2.2v3.8h-3.8c-.6 0-1 .4-1 1s.4 1 1 1h3.8V17c0 .1 0 .2-.2.2h-1.2c-.6 0-1 .4-1 1s.4 1 1 1h1.2c1.2 0 2.2-1 2.2-2.2V8.6c0-1.2-1-2.2-2.2-2.2h-11V4.8h14.5c.1 0 .2 0 .2.2v15.6Z"
      />
    </symbol>
  ),
  bolt: (
    <symbol id="boltIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 24">
      <path
        fill="currentColor"
        d="M16.5 5.2c.6-.6.9-1.3.9-2.1 0-1.6-1.3-3-3-3H3.6c-1.6 0-3 1.3-3 3s.3 1.6.9 2.1l2 2c.4.4.6 1 .6 1.6v.7c-.3 0-.7.2-.9.5-.2.3-.3.6-.3 1 0 .6.6 1.1 1.2 1.1v.9c-.3 0-.7.2-.9.5-.2.3-.3.6-.3 1 0 .6.6 1.1 1.2 1.1v.9c-.7 0-1.2.8-1.1 1.5 0 .7.6 1.2 1.3 1.2h.5l.1.1L8 23.4c.2.3.6.5 1.1.5.5 0 .8-.2 1.1-.5l3.1-4.1c.3-.4.5-.8.6-1.3h.1c.7-.1 1.2-.8 1.1-1.5 0-.3-.2-.7-.5-.9-.2-.2-.4-.2-.7-.3v-.9c.3 0 .7-.2.9-.5.2-.3.3-.6.3-1 0-.3-.2-.7-.5-.9-.2-.2-.4-.3-.7-.3v-.9c.7 0 1.2-.8 1.1-1.5 0-.6-.6-1.1-1.1-1.1 0-.4.3-.8.6-1.1l2-2v.1ZM9 20.4l-1.2-1.6 2.7-.3L9 20.4Zm2.2-4.7-4.4.5v-.9l4.4-.5v.9Zm0-3.6-4.4.5v-.9l4.4-.5v.9Zm.8-6c-.5.8-.8 1.6-.8 2.5l-4.4.5v-.4c0-1.3-.5-2.5-1.4-3.4l-2-2v-.2c0-.2.2-.3.3-.3h10.8c.2 0 .3.2.3.3v.2L12 6.1Z"
      />
    </symbol>
  ),
  brush: (
    <symbol id="brushIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M23.2 9.2 14.8.8c-.9-.9-2.4-.9-3.3 0L5.6 6.7c-.7.7-1 1.6-1 2.5 0 .9.4 1.8 1 2.5l.8.7-4.6 3.7C.8 16.9.2 18 .2 19.3c0 1.2.4 2.4 1.3 3.3.8.8 1.9 1.3 3.1 1.3h.2c1.2 0 2.4-.7 3.2-1.6l3.7-4.6.7.7c1.4 1.4 3.6 1.4 5 0l5.9-5.9c.4-.4.7-1 .7-1.7s-.2-1.2-.7-1.7l-.1.1Zm-20 8.7 4.6-3.7c.5-.4.8-1 .9-1.7 0-.7-.2-1.3-.7-1.8l-.7-.7c-.5-.5-.5-1.3 0-1.7l8.5 8.4c-.5.5-1.3.5-1.7 0l-.7-.7c-.5-.5-1.1-.7-1.8-.7s-1.3.4-1.7.9l-3.7 4.6c-.4.5-.9.7-1.5.8-.6 0-1.1-.2-1.6-.6-.4-.4-.6-1-.6-1.6 0-.6.3-1.1.8-1.5h-.1Zm10-13.8c-.2.2-.3.5-.3.8 0 .3.1.6.3.8.4.4 1.2.4 1.6 0l.9-.9.9.9L14 8.3c-.2.2-.3.5-.3.8 0 .3.1.6.3.8.4.4 1.2.4 1.6 0l2.6-2.6.9.9-1.7 1.7c-.4.4-.4 1.2 0 1.6.4.4 1.2.4 1.6 0l1.7-1.7.9.9-4.2 4.3-8.5-8.4 4.2-4.2h.1l.9.9-.9.9v-.1Z"
      />
    </symbol>
  ),
  bulldozer: (
    <symbol id="bulldozerIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 20">
      <path
        fill="currentColor"
        d="M15.247 16.365a.706.706 0 1 0 0-1.412.706.706 0 0 0 0 1.412ZM9.6 16.365a.706.706 0 1 0 0-1.412.706.706 0 0 0 0 1.412ZM4.094 16.365a.706.706 0 1 0 0-1.412.706.706 0 0 0 0 1.412Z"
      />
      <path
        fill="currentColor"
        d="M23.718 18.059s-5.506-8.753-5.648-8.894c0-.141-.423-.283-.564-.283-.282 0-.424 0-.565.283l-1.694 2.964h-.282V8.176c0-.423-.283-.705-.706-.705h-2.683l-.988-3.247H12c.424 0 .706-.283.706-.706V1.682c0-.423-.282-.706-.706-.706H2.682c-.423 0-.706.283-.706.706v1.836c0 .423.283.706.706.706h.283v4.235H2.54v-1.13c0-.423-.282-.705-.706-.705H.847c-.424 0-.706.282-.706.705 0 .424.282.706.706.706h.282v.424H.847c-.424 0-.706.282-.706.706v3.67c0 .424.282.706.706.706a3.468 3.468 0 0 0-.706 2.118 3.495 3.495 0 0 0 3.53 3.53h12.14c.989 0 1.977-.424 2.542-1.13l4.659 1.13c.282 0 .564 0 .706-.283.14-.282.14-.565 0-.706v-.141ZM16.8 12.27l.847-1.412 4.094 6.494-2.682-.706c0-.423.14-.706.14-1.13a3.346 3.346 0 0 0-2.4-3.246ZM3.53 13.54h12.14c1.13 0 2.118.988 2.118 2.118s-.988 2.117-2.117 2.117H3.529c-1.13 0-2.117-.988-2.117-2.117 0-1.13.988-2.118 2.117-2.118Zm.705-6.07V4.224h4.8l.989 3.247H4.235Zm0 1.553V8.74h9.317v3.247H1.413v-2.4h2.117c.424 0 .706-.282.706-.706v.142Zm7.06-6.636v.424H3.387v-.424h7.906Z"
      />
    </symbol>
  ),
  cart: (
    <symbol id="cartIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 20">
      <path
        fill="currentColor"
        d="M22.8 4.975h-1.35l-2.25-2.7C18.225 1.075 16.725.4 15.15.4c-1.575 0-2.175.375-3.075.975C11.175.7 10.125.4 9 .4c-1.575 0-3.075.675-4.05 1.875l-2.25 2.7H1.35C.675 4.975.225 5.5.225 6.1v3.075c0 .525.3.9.75 1.125v4.275c0 1.5 1.2 2.7 2.7 2.7h.3C4.575 18.7 6 19.6 7.5 19.6s2.925-.9 3.525-2.325h2.25C13.875 18.7 15.3 19.6 16.8 19.6s2.925-.9 3.525-2.325h.375c1.5 0 2.7-1.2 2.7-2.7V10.3c.45-.15.75-.6.75-1.125V6.1c0-.6-.525-1.125-1.125-1.125H22.8ZM16.65 14.2c.825 0 1.575.675 1.575 1.575 0 .9-.675 1.575-1.575 1.575-.9 0-1.575-.675-1.575-1.575 0-.9.675-1.575 1.575-1.575Zm-9.3 0c.825 0 1.575.675 1.575 1.575 0 .9-.675 1.575-1.575 1.575-.9 0-1.575-.675-1.575-1.575 0-.9.675-1.575 1.575-1.575Zm13.125.825h-.15c-.375-1.8-1.95-3.075-3.75-3.075s-3.375 1.275-3.75 3.075H11.1c-.375-1.8-1.95-3.075-3.75-3.075s-3.375 1.275-3.75 3.075c-.225 0-.375-.15-.375-.375v-4.275H21v4.275c0 .225-.15.375-.375.375h-.15Zm-8.7-10.05 1.05-1.275c.6-.675 1.425-1.05 2.25-1.05s1.725.375 2.25 1.05l1.05 1.275h-6.6ZM6.675 3.7c.9-1.05 2.55-1.35 3.75-.675L8.85 4.975H5.7L6.75 3.7h-.075Zm15 3.6v.75H2.4V7.3h19.275Z"
      />
    </symbol>
  ),
  concreteOrder: (
    <symbol id="concreteOrderIcon" viewBox="0 0 24 25">
      <path
        d="M18.4 16c-.3-1-1.2-1.6-2.2-1.6-1 0-1.8.6-2.2 1.6h-2.4V7.9H7l-1.6 4-3 .8v5h2.4c.3.9 1.1 1.5 2.2 1.5 1 0 1.8-.6 2.1-1.6h5c.3 1 1.1 1.6 2.1 1.6s1.9-.6 2.2-1.6h2.5V16h-2.5ZM7 17.6a.8.8 0 0 1-.8-.8c0-.4.3-.8.8-.8.4 0 .7.4.7.8s-.3.8-.7.8Zm3-1.6h-.9C8.8 15 8 14.4 7 14.4S5 15 4.8 16h-1v-2l1.8-.4H10V16Zm0-4H7l1-2.5h2v2.4Zm6.2 5.6a.8.8 0 0 1-.8-.8c0-.4.4-.8.8-.8s.8.4.8.8-.4.8-.8.8Z"
        fill="currentColor"
      />
      <path
        d="M17.2 6.5c-.4-.2-.9-.3-1.4-.3-1.9 0-3.4 1.7-3.4 3.7s1.5 3.7 3.4 3.7c.5 0 1-.1 1.4-.3l3.7-1.6V8l-3.7-1.6Zm2.1 4-2.7 1.3-.8.1c-1 0-1.9-.9-1.9-2s.9-2 2-2l.7.1 2.7 1.2v1.4Z"
        fill="currentColor"
      />
    </symbol>
  ),
  demolition: (
    <symbol id="demolitionIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M21.9 15.75V14.4c0-.975-.675-1.8-1.575-2.025V5.85l.375-.675a2.54 2.54 0 0 0-.675-3.225L18 .45c-.225-.15-.45-.225-.75-.15-.225 0-.45.15-.6.375L.375 22.35c-.225.3-.225.675-.075 1.05.15.375.525.525.9.525h8.55a.922.922 0 0 0 .825-.525L18.45 9.375v3c-.9.225-1.575 1.125-1.575 2.025v1.35a4.45 4.45 0 0 0-1.95 3.675c0 2.475 2.025 4.425 4.425 4.425s4.425-2.025 4.425-4.425-.75-2.85-1.95-3.675h.075Zm-10.95 2.925L9.15 21.9h-6l3.9-5.175 3.975 1.95h-.075Zm3.075-5.475-2.1 3.75-3.75-1.8 2.775-3.75 3.075 1.725v.075Zm5.325 8.775a2.529 2.529 0 0 1-2.55-2.55 2.529 2.529 0 0 1 2.55-2.55 2.529 2.529 0 0 1 2.55 2.55 2.529 2.529 0 0 1-2.55 2.55Zm.6-6.975h-1.125v-.6c0-.075.075-.225.225-.225h.75c.075 0 .225.075.225.225v.6h-.075Zm-.9-10.725-.9 1.65-1.95-1.35 1.5-2.025 1.275.9c.225.15.3.525.15.75l-.075.075ZM17.175 7.65 15 11.55 12.15 9.9 15 6.15l2.175 1.5Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </symbol>
  ),
  digger: (
    <symbol id="diggerIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="currentColor" clipPath="url(#a)">
        <path d="M19.626 16.828h-6.412v-.74h1.034a.761.761 0 0 0 .758-.757V11.3a.838.838 0 0 0-.055-.295L13.158 6.96a.727.727 0 0 0-.684-.444H11.44v-.11l3.659-1.682 2.79 4.084-2.402 1.423a.78.78 0 0 0-.37.702.704.704 0 0 0 .462.629l4.14 1.718a.71.71 0 0 0 .665-.055l2.31-1.368a.805.805 0 0 0 .351-.462.703.703 0 0 0-.092-.573l-1.811-3.049-3.031-6.006a1.884 1.884 0 0 0-1.201-.98 2.188 2.188 0 0 0-1.7.204 2.364 2.364 0 0 0-.721.702L9.148 3.836H7.06a.761.761 0 0 0-.758.757v1.94H5.71a.761.761 0 0 0-.757.759v2.827h-.74V8.18a.761.761 0 0 0-.757-.758h-.148V6.386a.761.761 0 0 0-.758-.758h-.887a.761.761 0 0 0-.757.758.75.75 0 0 0 .757.758h.148v.295h-.148a.761.761 0 0 0-.757.758v1.94H.758a.761.761 0 0 0-.758.758v4.473c0 .406.333.757.758.757H4.49v.74H3.456a3.43 3.43 0 0 0-3.438 3.437 3.43 3.43 0 0 0 3.438 3.437h16.133a3.43 3.43 0 0 0 3.438-3.437 3.43 3.43 0 0 0-3.438-3.437l.037-.037ZM2.44 10.1V8.918h.296V10.1H2.44Zm5.378-3.586V5.333h2.089v1.182H7.817Zm-1.33 3.604V8.031h5.488l.924 2.088H6.487Zm5.211 5.97v.739H6.025v-.74h5.673ZM1.552 14.59v-2.938h11.939v2.938H1.552Zm19.996 5.674a1.94 1.94 0 0 1-1.94 1.94H3.493a1.94 1.94 0 1 1 0-3.88h16.133a1.94 1.94 0 0 1 1.94 1.94h-.018Zm-4.01-9.518 2.68-1.589 1.053 1.793-1.349.794-2.402-.997h.018ZM16.78 2.45l2.717 5.378-.333.203-3.4-4.971a.43.43 0 0 1-.055-.389.577.577 0 0 1 .295-.388.691.691 0 0 1 .333-.092h.13a.42.42 0 0 1 .332.24l-.019.019Z" />
        <path d="M19.053 19.304H3.881a.946.946 0 0 0-.943.943c0 .517.425.942.943.942h15.172a.947.947 0 0 0 .943-.942.946.946 0 0 0-.943-.943Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </symbol>
  ),
  engineerInspection: (
    <symbol id="engineerInspectionIcon" viewBox="0 0 17 18">
      <path
        d="M15.2 14.6 12.7 12a6.6 6.6 0 0 0-.7-8.8 6 6 0 0 0-8.3.3 6.6 6.6 0 0 0-.2 8.8 5.9 5.9 0 0 0 8.3.7l2.5 2.6a.7.7 0 0 0 .4.2.6.6 0 0 0 .5-.2l.2-.5c0-.2 0-.3-.2-.5ZM8 13c-1 0-1.9-.3-2.7-.8a5 5 0 0 1-1.7-2.3 5.2 5.2 0 0 1 1-5.4c.7-.7 1.5-1.2 2.4-1.4 1-.2 2 0 2.8.3a5.2 5.2 0 0 1 1.5 8.1c-.9 1-2 1.5-3.3 1.5Z"
        fill="currentColor"
      />
    </symbol>
  ),
  excavator: (
    <symbol id="excavatorIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
        <path d="M11.62 8.12h-3.7a.58.58 0 0 0-.58.58v1.84c0 .16.06.3.16.4l.92.92c.1.1.26.16.4.16v-.12.12h3.7c.38 0 .66-.38.54-.76l-.92-2.78c-.08-.24-.3-.4-.54-.4l.02.04ZM8.48 9.26h2.7l.54 1.62H9.06l-.58-.58V9.26Z" />
        <path d="M20.84 15.84c.38 0 .68-.3.68-.68v-3h.24c.32 0 .58-.22.66-.52l.34-1.32h.38c.38 0 .68-.3.68-.68v-2.3c0-.38-.3-.68-.68-.68h-.52l-.38-.74c-.26-.52-.62-1.2-.96-1.9-.34-.7-.7-1.38-.96-1.9-.14-.26-.24-.48-.32-.62-.04-.08-.06-.14-.08-.18v-.04h-.04c-.54-1.06-1.9-1.34-2.86-.76-.3.18-.54.42-.72.7L9.62 3.44h-2.2c-.38 0-.68.3-.68.68V6.2h-.7c-.38 0-.68.3-.68.68v3h-.94V7.8c0-.38-.3-.68-.68-.68H3.5V5.96c0-.38-.3-.68-.68-.68H1.9c-.38 0-.68.3-.68.68 0 .38.3.68.68.68h.24v.48H1.9c-.38 0-.68.3-.68.68v2.08H.98c-.38 0-.68.3-.68.68v4.62c0 .38.3.68.68.68h3.94v.94H3.76C1.86 16.8.3 18.36.3 20.26s1.56 3.46 3.46 3.46h16.62c1.9 0 3.46-1.56 3.46-3.46s-1.56-3.46-3.46-3.46h-6.7v-.94h1.16c.38 0 .68-.3.68-.68v-4.16c0-.1-.02-.2-.06-.28l-1.84-4.16c-.1-.24-.36-.4-.62-.4h-1.16v-.22l5-1.88 1.76 2.56h-.06c-.38 0-.68.3-.68.68v2.3c0 .38.3.68.68.68h.38l.34 1.32c.08.3.36.52.66.52h.24v3c0 .38.3.68.68.68v.02ZM22.48 8v.94h-3.24V8h3.24Zm-3.8-6.16 2.42 4.78h-.82L17.5 2.56a.62.62 0 0 1-.08-.46.729.729 0 0 1 .88-.54c.18.04.32.14.4.28h-.02ZM2.6 9.86v-1.4h.48v1.4H2.6ZM16.28 2.6v.06-.06Zm.04.14c0 .02 0 .04.02.06v-.06h-.02ZM9.48 4.76l.98.98v.42H8.14v-1.4h1.34Zm2.82 11.08v.94H6.28v-.94h6.02Zm1.86-1.36H1.68v-3.24h4.4c.38 0 .68-.3.68-.68v-3h5.8l1.6 3.62v3.32-.02Zm6.22 7.84H3.76c-1.16 0-2.08-.94-2.08-2.08 0-1.14.94-2.08 2.08-2.08h16.62c1.16 0 2.08.94 2.08 2.08 0 1.14-.94 2.08-2.08 2.08Z" />
        <path d="M19.84 19.58H3.88c-.3 0-.54.24-.54.54 0 .3.24.54.54.54h15.96c.3 0 .54-.24.54-.54 0-.3-.24-.54-.54-.54Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </symbol>
  ),
  finalYards: (
    <symbol id="finalYardsIcon" viewBox="0 0 18 18">
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1.4a2 2 0 0 1 2 0l4.8 3c.3 0 .5.3.7.5a.7.7 0 0 1 .2.3l.2 1V12c0 .4-.1.8-.3 1.1-.2.4-.4.6-.8.8L10 17a2 2 0 0 1-.7.2.7.7 0 0 1-.7 0A2 2 0 0 1 8 17l-4.8-3c-.4-.2-.6-.4-.8-.8-.2-.3-.3-.7-.3-1V6.1c0-.3 0-.7.2-1a.8.8 0 0 1 .2-.3l.6-.6L5.4 3c.1-.2.2-.2.4-.3l2.2-1.3Zm6.2 11.3-4.6 2.7V9.5l4.9-3V12l-.1.4-.2.3ZM3.3 6.6l4.9 3v5.8l-4.6-2.7a.7.7 0 0 1-.2-.3l-.1-.4V6.6Zm5.2-4h.7l.1.1 4.4 2.6-4.9 3-4.8-3 4.5-2.6Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M.6.4h16.7v17.5H.6z" />
        </clipPath>
      </defs>
    </symbol>
  ),
  hammer: (
    <symbol id="hammerIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22">
      <path
        fill="currentColor"
        d="M23.4 9.7 22 8.3c-.4-.4-1-.8-1.6-.9h-.2c-.4-.1-.7-.5-.8-.9-.1-.7-.4-1.3-.9-1.8l-2-2C14.9 1.1 12.6.3 10.3.5c-3.7.5-5.7 3.4-6 4-.2.3-.2.8 0 1.1.2.4.5.6.9.6 1.3.2 2.5.6 3.7 1.2.2.1.4.2.6.4l-7.9 6.6c-.9.7-1.4 1.8-1.4 2.9 0 1.1.4 2.2 1.2 3 .8.8 1.8 1.2 2.8 1.2h.2c1.1 0 2.1-.6 2.9-1.4l7.1-8.4c.4 0 .7.4.8.8v.2c.2.6.5 1.2.9 1.6l1.3 1.3c.9.9 2.5.9 3.4 0l2.6-2.6c.4-.4.7-1 .7-1.7s-.2-1.1-.6-1.6h-.1Zm-18 8.8c-.3.4-.7.6-1.2.6s-.9-.2-1.2-.5c-.3-.3-.5-.8-.5-1.2 0-.5.2-.9.6-1.2l8.1-6.9.1.1 1 1L5.9 18l-.5.5Zm16.3-7.3-2.6 2.6-1.3-1.3c-.1-.1-.2-.3-.3-.5v-.2c-.3-1.3-1.4-2.3-2.7-2.6-.2 0-.4-.1-.5-.3l-1.2-1.2c-.9-.9-2-1.7-3.1-2.4-.8-.4-1.7-.8-2.5-1 .7-.7 1.8-1.4 3.2-1.6 1.6-.2 3.2.4 4.3 1.5l2 2c.1.1.2.3.3.5.2 1.3 1.2 2.4 2.6 2.7h.2c.2 0 .4.2.5.3l1.3 1.3-.2.2Z"
      />
    </symbol>
  ),
  hook: (
    <symbol id="hookIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 24">
      <path
        fill="currentColor"
        d="M14.3 2.25c.6 0 1.05-.45 1.05-1.05 0-.6-.45-1.05-1.05-1.05H1.85C1.25.15.8.6.8 1.2c0 .6.45 1.05 1.05 1.05h3.6V4.8h-.075c-.825 0-1.575.375-2.1 1.05L2 7.575a2.652 2.652 0 0 0-.225 2.775l2.25 4.5c.45.9 1.35 1.425 2.325 1.425h.675v1.275c0 .375.15.675.45.9l1.65 1.125c.15.075.225.225.225.375v1.5c0 .3-.225.45-.45.45H7.325c-.3 0-.45-.225-.45-.45v-.75c0-.6-.45-1.05-1.05-1.05-.6 0-1.05.45-1.05 1.05v.75c0 1.425 1.2 2.625 2.625 2.625h1.575c1.425 0 2.625-1.2 2.625-2.625v-1.5c0-.9-.45-1.725-1.2-2.175l-1.125-.75v-.675h.675c.975 0 1.875-.525 2.325-1.425l2.25-4.5c.45-.9.375-1.95-.225-2.775l-1.275-1.725c-.525-.675-1.275-1.05-2.1-1.05h-.075v-2.55h3.6l-.15-.075Zm-9 4.65h5.475c.15 0 .3 0 .375.225l1.275 1.725c.15.15.15.375 0 .525l-.825 1.65H4.4l-.825-1.65c-.075-.15 0-.375 0-.525L4.85 7.125a.412.412 0 0 1 .375-.225H5.3Zm2.25-2.1V2.25h.975V4.8H7.55Z"
      />
    </symbol>
  ),
  impactHammer: (
    <symbol id="impactHammerIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="currentColor" clipPath="url(#a)">
        <path d="M23.232 2.88H21.36a.738.738 0 0 0-.72.72v.24h-3.216V2.688a.738.738 0 0 0-.72-.72h-1.152V.816a.738.738 0 0 0-.72-.72H9.216a.738.738 0 0 0-.72.72v1.152H7.344a.738.738 0 0 0-.72.72V3.84H3.408V3.6a.738.738 0 0 0-.72-.72H.816a.738.738 0 0 0-.72.72v3.744c0 .384.336.72.72.72h1.872c.384 0 .72-.336.72-.72v-.24h3.216v2.064c0 .288.144.528.384.624l1.488.72v1.44c0 .384.336.72.72.72h.24v2.544c0 .384.336.72.72.72h.24v5.808c0 .144 0 .288.144.384l.912 1.392c.144.192.336.336.624.336.24 0 .48-.144.576-.336l.912-1.392a.545.545 0 0 0 .144-.384v-5.808h.24c.384 0 .72-.336.72-.72v-2.544h.24c.384 0 .72-.336.72-.72v-1.44l1.488-.72c.24-.144.384-.384.384-.624V7.104h3.216v.24c0 .384.336.72.72.72h1.872c.384 0 .72-.336.72-.72V3.6a.738.738 0 0 0-.72-.72h-.096ZM1.968 6.624h-.432V4.32h.432v2.304Zm4.656-.912H3.408V5.28h3.216v.432Zm3.312-4.176h4.176v.432H9.936v-.432Zm2.304 20.112-.24.336-.24-.336v-5.616h.432v5.616h.048Zm.96-7.056h-2.304v-1.824H13.2v1.824Zm2.784-5.808-1.488.72c-.24.144-.384.384-.384.624v1.152H9.936v-1.152c0-.288-.144-.528-.384-.624l-1.488-.72V3.408h7.92v5.376Zm4.656-3.072h-3.216V5.28h3.216v.432Zm1.872.912h-.432V4.32h.432v2.304Z" />
        <path d="M9.696 6.144h4.656c.384 0 .72-.336.72-.72a.738.738 0 0 0-.72-.72H9.696a.738.738 0 0 0-.72.72c0 .384.336.72.72.72ZM9.696 8.496h4.656c.384 0 .72-.336.72-.72a.738.738 0 0 0-.72-.72H9.696a.738.738 0 0 0-.72.72c0 .384.336.72.72.72Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </symbol>
  ),
  pliers: (
    <symbol id="pliersIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="currentColor" clipPath="url(#a)">
        <path d="m23 4.1-.6-.6c-.4-.4-1.1-.4-1.5 0l-1.8 1.8c-.2.2-.3.4-.3.6 0 .7-.5 1.1-.9 1.2-.3 0-.5-.1-.6-.2 0-.1-.3-.4-.2-.6 0-.4.5-.8 1.2-.9.2 0 .5-.1.6-.3l1.8-1.8c.2-.2.3-.5.3-.7 0-.2-.1-.5-.3-.7l-.6-.6C18.9 0 17-.2 15.6.8l-2.8 2c-.9.6-1.4 1.5-1.5 2.6l-.2 2.4c-.6.2-1.1.5-1.5.9-1.1-.1-2.2 0-3.3.1-1.7.3-3.3 1-4.8 2.1-.8.6-1.3 1.5-1.4 2.6 0 1 .3 2.1 1 2.8 1.2 1.2 3.1 1.4 4.5.4.3-.2.8-.5 1.3-.8.6-.3 1.3-.6 2-.8-.2.7-.5 1.3-.8 2-.2.5-.5.9-.8 1.3-1 1.4-.8 3.3.4 4.6.7.7 1.6 1 2.5 1h.3c1 0 2-.6 2.6-1.4 1.1-1.5 1.8-3.1 2.1-4.8.2-1 .2-2.1 0-3.2.4-.4.7-.9.9-1.5l2.4-.2c1 0 2-.6 2.6-1.5l2-2.8c1-1.4.8-3.3-.4-4.4l.3-.1ZM2.3 13.6c0-.4.2-.8.6-1 1.2-.9 2.5-1.5 3.9-1.7.5 0 1-.1 1.6-.1h.3c-.2.8 0 1.6.2 2.3-.9.2-1.8.6-2.6 1-.6.3-1.1.6-1.5.9-.6.4-1.4.3-1.9-.2-.3-.3-.5-.7-.4-1.2h-.2Zm19.3-6.3-2 2.8c-.2.3-.6.6-1 .6h-2c0-.3-.2-.8-.5-1.2l.5-.5c.5.2 1 .3 1.4.2 1.3-.1 2.4-1.2 2.7-2.6l.9-.9c.4.5.4 1.1 0 1.6Zm-7 4.2c0 .5-.2 1-.6 1.4l-.3.3c-.8.5-1.8.4-2.5-.3s-.8-2 0-2.8c.3-.3.7-.5 1.2-.6h.2c.5 0 1 .2 1.4.6.4.4.6.9.6 1.4Zm-1.3 5.8c-.2 1.4-.8 2.7-1.7 3.9-.2.3-.6.5-1 .6-.4 0-.9-.1-1.2-.5-.5-.5-.6-1.3-.2-1.9.3-.4.6-.9.9-1.5.4-.9.8-1.8 1.1-2.7.5.2 1 .3 1.5.3h.8c0 .6 0 1.3-.1 1.8h-.1Zm0-9.8v-2c.2-.4.4-.8.7-1l2.8-2c.5-.4 1.2-.3 1.6 0l-.9.9c-1.4.3-2.5 1.4-2.6 2.7 0 .5 0 1 .2 1.4l-.5.5c-.4-.2-.9-.4-1.4-.5h.1Z" />
        <path d="M12.6 10.4c-.6 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2 1.2-.5 1.2-1.2-.5-1.2-1.2-1.2Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </symbol>
  ),
  pump: (
    <symbol id="pumpIcon" viewBox="0 0 20 18">
      <path
        d="M2.7 14.6c.3-1 1-1.5 2-1.5.9 0 1.7.6 2 1.5h2.2V7h4.3l1.4 3.8 2.8.7v4.6h-2.2c-.3.9-1 1.5-2 1.5s-1.7-.6-2-1.5H6.7c-.3.9-1.1 1.5-2 1.5-1 0-1.7-.6-2-1.5H.8v-1.5h1.9ZM13.2 16c.4 0 .7-.4.7-.8s-.3-.7-.7-.7c-.4 0-.7.3-.7.7 0 .4.3.8.7.8Zm-2.9-1.5h.9c.3-1 1-1.5 2-1.5s1.7.6 2 1.5h.8v-1.9l-1.6-.4h-4v2.3Zm0-3.8h2.8l-.9-2.3h-1.9v2.3ZM4.7 16c.4 0 .7-.4.7-.8s-.3-.7-.7-.7c-.4 0-.8.3-.8.7 0 .4.4.8.8.8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m6.4 10.2 2 4.4H1.5l2-4.2c.2-.4.6-1.1 1.5-1.1s1.3.6 1.4.9Zm-1.4 2c.4 0 .7-.2.7-.7 0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7 0 .5.3.8.7.8Z"
        fill="currentColor"
      />
      <path
        d="M13.3 1a1 1 0 0 0-.9 0c-.3 0-.5.3-.4.6L13.3 5 9.8 3.2a1 1 0 0 0-.6 0 1 1 0 0 0-.6.2L4.3 8.7c0 .1-.3.4 0 .4h1.3l3.8-4.6 4.7 2.4h.9c.3-.1.5-.4.4-.7L14 2.8l3.8 2h.5l.5-.1c.2-.1.3-.3.3-.4 0-.2-.1-.4-.3-.4l-5.6-3Z"
        fill="currentColor"
      />
    </symbol>
  ),
  robot: (
    <symbol id="robotIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="currentColor" clipPath="url(#a)">
        <path d="M21.45 17.25H11.4v-5.325l5.475-4.5v3.15h-.225a2.529 2.529 0 0 0-2.55 2.55V14.7c0 .3.15.675.45.825l1.2.75c.45.3 1.05.15 1.35-.3.15-.225.225-.45.15-.75 0-.225-.225-.45-.45-.6l-.75-.525v-1.05c0-.3.3-.6.6-.6h2.325c.3 0 .6.3.6.6v1.05l-.75.525c-.45.3-.6.9-.3 1.35.3.45.975.6 1.35.3l1.2-.75c.3-.15.45-.525.45-.825v-1.575a2.529 2.529 0 0 0-2.55-2.55h-.225v-4.8l.825-.675c.525-.45.825-1.05.9-1.725 0-.675-.15-1.35-.6-1.875L19.35.9a2.603 2.603 0 0 0-1.725-.9c-.675 0-1.35.15-1.875.6L6.9 7.875h-.675A3.361 3.361 0 0 0 2.85 11.25v6.075h-.6a2.529 2.529 0 0 0-2.55 2.55v1.575A2.529 2.529 0 0 0 2.25 24h18.825a2.529 2.529 0 0 0 2.55-2.55v-1.575a2.529 2.529 0 0 0-2.55-2.55l.375-.075ZM6.525 9.825H8.1c.75 0 1.35.6 1.35 1.35v6.075H5.1v-6.075c0-.75.6-1.35 1.35-1.35h.075ZM9.75 8.25l4.425-3.675 1.2 1.575L11.1 9.675c-.3-.6-.75-1.125-1.35-1.425Zm5.925-4.875L17.25 2.1c.15-.075.3-.15.45-.15.15 0 .3.075.375.225l.525.6c.075.15.15.3.15.45 0 .15-.075.3-.225.375l-1.65 1.35-1.2-1.575ZM2.025 19.8c0-.3.3-.6.6-.6H21.45c.3 0 .6.3.6.6v1.575c0 .3-.3.6-.6.6H2.625c-.3 0-.6-.3-.6-.6V19.8Z" />
        <path d="M7.05 12.975h.6c.375 0 .75-.45.75-.975 0-.525-.3-.975-.75-.975h-.6c-.375 0-.75.45-.75.975 0 .525.3.975.75.975Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </symbol>
  ),
  shovel: (
    <symbol id="shovelIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M23.3 3.2 20.7.6c-.8-.8-2.2-.8-3 0l-.9.9c-.4.4-.6.9-.6 1.5s.6.8 1.3 1.6L9.1 13l-1.2-1.2c-.8-.8-2.2-.8-3 0l-3 3c-.4.4-.7.9-.9 1.4-.3.7-.5 1.4-.6 2.2-.2 1.2 0 2.5.3 3.7.2.7.7 1.2 1.4 1.4.8.2 1.6.3 2.4.3h1.2c.7-.1 1.5-.3 2.2-.6.5-.2.9-.5 1.4-.9l3-3c.8-.8.8-2.2 0-3L11 15l8.4-8.4c.8.7 1.6 1.4 1.7 1.4.5 0 1.1-.2 1.5-.6l.9-.9c.4-.4.6-.9.6-1.5s-.2-1.1-.6-1.5l-.2-.3ZM9.8 16.5l1 1c.1.1.1.3 0 .4l-3 3c-.2.2-.5.4-.8.5-.5.2-1.1.4-1.7.5-1 .1-1.9 0-2.9-.2 0 0-.1 0-.2-.2-.3-1-.3-1.9-.2-2.9 0-.6.2-1.2.5-1.7.1-.3.3-.5.5-.8l3-3c.1-.1.3-.1.4 0l1.3 1.1-1.4 1.4c-.5.5-.5 1.2 0 1.7.5.6 1.2.6 1.7 0l1.4-1.4.3.3.1.3ZM22.1 5l-.9.9c-.1.1-.3.1-.4 0l-2.6-2.6c-.1-.1-.1-.3 0-.4l.9-.9c.1-.1.3-.1.4 0l2.6 2.6V5Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </symbol>
  ),
  trowel: (
    <symbol id="trowelIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M23.1 1.1c-.6-.7-1.6-1-2.5-.7L8 4.2c-.8.3-1.5.9-1.7 1.8-.2.9 0 1.7.7 2.4l3.5 3.5-1.4 1.4c-.9-.9-2.3-1-3.3-.2l-3.9 3C.9 16.9.3 18 .2 19.3c0 1.3.4 2.5 1.3 3.4.8.8 1.9 1.3 3.1 1.3h.3c1.3 0 2.4-.7 3.2-1.7l3-3.9c.8-1 .7-2.4-.2-3.3l1.4-1.4 3.5 3.5c.5.5 1.1.7 1.7.7h.6c.9-.2 1.5-.8 1.8-1.7l3.8-12.6c.3-.9 0-1.8-.6-2.4v-.1Zm-6.6 8.3c.5-.5.5-1.3 0-1.9-.5-.5-1.3-.5-1.9 0l-2.4 2.4-3.3-3.3L21 3l-3.6 12.1-3.3-3.3 2.4-2.4Zm-7.6 7.4L6 20.6c-.3.4-.8.7-1.3.7s-1-.2-1.4-.5c-.4-.4-.6-.9-.5-1.4 0-.5.3-1 .7-1.3l3.7-2.9 1.7 1.7v-.1Z"
      />
    </symbol>
  ),
  worker: (
    <symbol id="workerIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 24">
      <path
        fill="currentColor"
        d="m15.738 14.625-3.263-.525a3.444 3.444 0 0 1-.188-1.05 4.44 4.44 0 0 0 2.1-3.75V8.137h.226a1.6 1.6 0 0 0 1.612-1.612c0-.9-.638-1.5-1.463-1.613a4.909 4.909 0 0 0-1.387-2.774A5.132 5.132 0 0 0 11.537.975c0-.45-.3-.75-.712-.75H8.95c-.375 0-.713.3-.713.712-.712.3-1.312.713-1.837 1.2a4.909 4.909 0 0 0-1.388 2.775A1.626 1.626 0 0 0 3.55 6.525c0 .825.712 1.612 1.612 1.612h.225V9.3c0 1.537.788 2.925 2.1 3.75 0 .375-.112.712-.187 1.05l-3.263.525C1.862 14.963.325 16.8.325 19.012v4.276c0 .375.3.712.712.712h17.7c.375 0 .713-.3.713-.712v-4.276c0-2.175-1.575-4.012-3.712-4.387ZM13.6 15.713l.563.075-1.988 1.537-.675-.262 2.137-1.35H13.6Zm-3.9-14.1h.45V3H9.7V1.613Zm-2.25 1.5a3.33 3.33 0 0 1 .825-.6V3.75c0 .375.3.713.712.713h1.876c.375 0 .712-.3.712-.713V2.513c.3.15.6.375.825.6.487.487.825 1.087.938 1.762H6.512c.15-.675.45-1.275.938-1.763ZM5.275 6.75a.242.242 0 0 1-.225-.225c0-.113.112-.225.225-.225h9.3c.113 0 .225.112.225.225a.242.242 0 0 1-.225.225h-9.3ZM6.887 9.3V8.137h6.038V9.3c0 1.65-1.35 3.037-3.038 3.037A3.025 3.025 0 0 1 6.85 9.3h.037Zm-.637 6.412 2.137 1.35-.675.263-1.987-1.537.562-.075H6.25Zm2.962 6.863H1.787v-3.563c0-1.35.825-2.474 2.1-2.887l3.3 2.55c.188.15.45.188.675.113l1.388-.563v4.313l-.038.037Zm-.937-7.237A.598.598 0 0 0 8.537 15c.188-.45.338-.938.375-1.387.638.15 1.35.15 1.988 0 .075.487.188.937.375 1.387.037.15.15.262.262.338l-1.65 1.05-1.65-1.05h.038Zm9.787 7.237H10.6v-4.313l1.388.563a.727.727 0 0 0 .675-.113l3.3-2.587c1.237.413 2.1 1.538 2.1 2.887v3.563Z"
      />
    </symbol>
  ),
  wrench: (
    <symbol id="wrenchIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M22.2 5.2c-.5-.5-1.2-.5-1.7 0L18 7.7c-.4.4-1.2.4-1.7 0-.2-.2-.3-.5-.3-.8 0-.3.1-.6.3-.8l2.5-2.5c.5-.5.5-1.2 0-1.7A5.54 5.54 0 0 0 14.7.1c-1.5 0-3.1.6-4.2 1.7l-.6.6C8.4 3.9 7.7 5.9 7.8 8c.1 1.4-.4 2.7-1.4 3.7l-5.2 5.2c-1.6 1.6-1.6 4.2 0 5.8.8.8 1.8 1.2 2.9 1.2 1.1 0 2.1-.4 2.9-1.2l5.2-5.2c1-1 2.3-1.5 3.7-1.4 2.1.2 4.1-.6 5.6-2.1l.6-.6c1.1-1.1 1.7-2.6 1.7-4.2s-.6-3.1-1.7-4.2l.1.2Zm-12 2.6c-.1-1.4.4-2.7 1.4-3.7l.6-.6c.7-.7 1.6-1 2.5-1 .9 0 1.1.1 1.5.3l-1.5 1.5c-.7.7-1 1.6-1 2.5 0 .9.4 1.8 1 2.5s1.6 1 2.5 1c.9 0 1.8-.4 2.5-1l1.5-1.5c.2.5.3 1 .3 1.5 0 .9-.4 1.8-1 2.5l-.6.6c-1 1-2.3 1.5-3.7 1.4-2.1-.2-4.1.6-5.6 2.1l-5.2 5.2c-.7.7-1.8.7-2.5 0-.3-.3-.5-.8-.5-1.2 0-.5.2-.9.5-1.3l5.2-5.2c1.5-1.5 2.2-3.5 2.1-5.6Z"
      />
    </symbol>
  ),
};

export type TaskIcon = keyof typeof icons;

export const keys = Object.keys(icons) as TaskIcon[];
