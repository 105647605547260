/* eslint-disable immutable/no-mutation */

import { useAppContext } from '@contexts/app';
import { usePanel } from '@hooks/usePanel';
import { UserRole } from '@root/globalTypes';
import { appRoutes } from '@utils/consts';
import { parseRoute } from '@utils/i18n';
import { localStore } from '@utils/localStore';
import { ReactNode } from 'react';

export const ProtectedRoute = ({
  children,
  allowedRoles = [],
  panel,
}: {
  children: ReactNode;
  allowedRoles?: UserRole[];
  panel?: boolean;
}) => {
  const {
    isLogged,
    role,
    admin: { isLogged: isAdminLogged },
  } = useAppContext();

  const { parseUrl } = usePanel();

  const user = isLogged || Boolean(localStore.getItem('app-user'));
  const admin = isAdminLogged || Boolean(localStore.getItem('app-admin'));

  const redirectToLogin = () => {
    window.location.pathname = `/${parseRoute(parseUrl(appRoutes.index))}`;
    return null;
  };

  if (panel) {
    if (admin) return children;
    return redirectToLogin();
  }
  if (!user) return redirectToLogin();
  if (role && allowedRoles.length && !allowedRoles.includes(role as UserRole))
    return redirectToLogin();
  return children;
};
