import { useAppContext } from '@contexts/app';
import { useWebsocketContext } from '@contexts/websocket';
import { localStore } from '@utils/localStore';
import { initialState } from '@contexts/app/constants';
import { useQueryClient } from '@tanstack/react-query';
import { getSlug, removeSlug } from './slug/helpers';
import { useQueryParams } from './queryParams';

export const useLogout = () => {
  const { setData } = useAppContext();
  const { ws } = useWebsocketContext();

  const queryClient = useQueryClient();
  const { setParams } = useQueryParams();

  return (type: 'admin' | 'user') => {
    queryClient.removeQueries();
    ws?.disconnect();
    if (!localStore.getItem('app-impersonate')) localStore.removeItem(`app-${type}`);
    else {
      localStore.removeItem('app-user');
      localStore.removeItem('app-admin');
    }
    localStore.removeItem('app-impersonate');
    setParams({ isLogout: getSlug() ? 'true' : undefined });
    if (type === 'admin') setData((prev) => ({ ...prev, admin: initialState.admin }));
    else setData((prev) => ({ ...initialState, ...prev.admin }));
    removeSlug();
  };
};
