import { useQuery } from '@tanstack/react-query';
import { ApiError, Notification } from '@root/globalTypes';
import { queryFetch } from '@services/api';
import { queryKeys } from '@utils/consts';
import { useAppContext } from '@contexts/app';

export interface NotificationsRespSuccess {
  success: boolean;
  records: Notification[];
  unreadNotifications: number;
  missingOptionalSubtasks: number;
}

const fetchNotifications = async (): Promise<NotificationsRespSuccess> => {
  return queryFetch({ url: `notifications` });
};

export const useGetNotifications = () => {
  const { isLogged, admin } = useAppContext();

  const { data, error, isFetching } = useQuery<NotificationsRespSuccess, ApiError>({
    queryKey: queryKeys.notifications,
    queryFn: () => fetchNotifications(),
    placeholderData: (previousData) => previousData as NotificationsRespSuccess,
    enabled: isLogged && !admin.isLogged,
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
