import { version } from '../../../package.json';
import { cn } from '@utils/cn';

export const Footer = ({ isCompact }: { isCompact?: boolean }) => (
  <footer
    className={cn([
      'footer t-auto flex flex-wrap items-center gap-5 px-5 pb-9',
      isCompact ? 'mx-auto mb-auto w-full max-w-md flex-col' : 'container px-4',
    ])}>
    <div className="w-full border-t border-neutral-300" />
    <span
      className={cn(['flex flex-col font-inter text-neutral-400', isCompact && 'items-center'])}>
      <span>© 2025 Retti</span>
      <span>v{version}</span>
    </span>
  </footer>
);
