import { useQuery } from '@tanstack/react-query';
import { queryKeys, uuidRegEx } from '@utils/consts';
import { queryFetch } from '@services/api';
import { ApiError } from '@root/globalTypes';
import { parseSpecialtiesAttributes } from '@utils/textHelpers';
import { UserRespSuccess } from './types';

export const useGetUser = (id: string) => {
  const { data, error, isFetching } = useQuery<UserRespSuccess, ApiError>({
    queryKey: [...queryKeys.user, id],
    queryFn: () => queryFetch({ url: `users/${id}` }),
    select: (resp) => {
      return {
        ...resp,
        data: {
          ...resp.data,
          specialties: parseSpecialtiesAttributes(resp.data.specialties || [], 'camel'),
        },
      };
    },
    enabled: Boolean(id && uuidRegEx.test(id)),
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
