import { useQuery } from '@tanstack/react-query';
import { ApiError } from '@root/globalTypes';
import { queryFetch } from '@services/api';
import { queryKeys, uuidRegEx } from '@utils/consts';
import { FiltersRespSuccess } from './types';
import { useAppContext } from '@contexts/app';
import { usePanel } from '@hooks/usePanel';

export const useGetFilters = () => {
  const {
    id: userId,
    admin: { data: adminData },
  } = useAppContext();

  const { panel } = usePanel();

  const id = userId || adminData?.id;

  const { data, error, isFetching } = useQuery<FiltersRespSuccess, ApiError>({
    queryKey: queryKeys.filters,
    queryFn: () =>
      queryFetch({ url: [panel ? 'admins' : 'users', id, 'filters'].join('/'), panel }),
    enabled: Boolean(id && uuidRegEx.test(id)),
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
