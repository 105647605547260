import { OnDeckTask } from '@services/onDeck';
import { formatTime } from '@utils/i18n';
import { differenceInMinutes, parseISO } from 'date-fns';

export const alertStyleMap = {
  error: {
    icon: 'text-danger-400',
    task: 'bg-danger-500 border-danger-500',
  },
  warning: {
    icon: 'text-alerts-warning',
    task: 'bg-warning-500 border-warning-500',
  },
};

const externalVariants: Record<string, string> = {
  pending: 'text-neutral-300 border-neutral-200 bg-pending',
  approved: 'bg-white text-black',
  rejected: 'text-white bg-danger-500',
  inProgress: 'text-white bg-blue-500',
};

export const getExternalTaskStyle = (status?: string) => externalVariants[status || 'pending'];

export const sortByDate = <T>(array: T[], dateProp: keyof T, direction: 'asc' | 'desc') => {
  return array.sort((a, b) =>
    direction === 'desc'
      ? parseISO(b[dateProp] as string).getTime() - parseISO(a[dateProp] as string).getTime()
      : parseISO(a[dateProp] as string).getTime() - parseISO(b[dateProp] as string).getTime(),
  );
};

export const groupTasksByDate = (tasks: OnDeckTask[]) => {
  const RANGE_IN_MINUTES: number = 30;

  const initialTasks: OnDeckTask[] = sortByDate(tasks, 'startsAt', 'asc');
  let groups: Record<string, OnDeckTask[]> = {};
  let usedTaskIds: string[] = [];

  initialTasks.forEach((task) => {
    if (!initialTasks.find((el) => el.id === task.id)) return;
    const group: OnDeckTask[] = [
      task,
      ...initialTasks
        .filter((item) => item.id !== task.id)
        .filter(
          (item) =>
            differenceInMinutes(new Date(task.startsAt), new Date(item.startsAt)) <=
              RANGE_IN_MINUTES &&
            differenceInMinutes(new Date(task.startsAt), new Date(item.startsAt)) >=
              -RANGE_IN_MINUTES,
        ),
    ];
    groups = { ...groups, [task.startsAt]: group };
  });

  const sortedGroupsByLength = Object.keys(groups).sort(
    (a, b) => groups[b].length - groups[a].length,
  );

  sortedGroupsByLength.forEach((el) => {
    groups = {
      ...groups,
      [el]: sortByDate(
        groups[el].filter((i) => !usedTaskIds.includes(i.id)),
        'startsAt',
        'asc',
      ),
    };
    usedTaskIds = [...usedTaskIds, ...groups[el].map((i) => i.id)];
  });

  return groups;
};

export const showTimeOrOrder = (task: OnDeckTask) => {
  const { materials, type, startsAt } = task;
  const concrete = materials?.find((el) => el.product === 'Concrete');

  if (type === 'Final Yards' && concrete) {
    const { estimatedQuantity, finalQuantity } = concrete;
    if (finalQuantity) return `F:${finalQuantity}`;
    if (estimatedQuantity) return `E:${estimatedQuantity}`;
  }

  return startsAt ? formatTime(new Date(startsAt)).split(' ')[0] : '';
};
