import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { handleOnSettled } from '@utils/formHelpers';
import { useAppContext } from '@contexts/app';
import { useTranslation } from 'react-i18next';
import { localStore } from '@utils/localStore';
import { modelUserData } from '@utils/userData';
import {
  FormattedSignInData,
  FormattedSignInResp,
  SignInReq,
  useSignIn,
} from '@services/auth/loginUser';
import { useErrors } from '@hooks/errors';
import { Admin, User } from '@root/globalTypes';

export const useLogInForm = ({ successHandler }: { successHandler: () => void }) => {
  const handleErrors = useErrors();
  const { t } = useTranslation('app');
  const { setData } = useAppContext();
  const formSchema = z.object({
    email: z
      .string()
      .trim()
      .email({ message: t('forms.email.required') }),
    password: z
      .string()
      .trim()
      .min(8, {
        message: t('forms.password.required'),
      }),
  });

  const defaultValues: SignInReq = {
    email: '',
    password: '',
  };
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const updateLocalStorage = (type: 'admin' | 'user', id: string) => {
    const item = localStore.getItem(`app-${type}`);
    const parsed = typeof item === 'string' ? (JSON.parse(item) as Record<string, string>) : item;
    localStore.updateItem(`app-${type}`, { ...parsed, id });
  };

  const resolveAdmin = (admin: Admin) => {
    setData((prev) => ({ ...prev, admin: { data: admin, isLogged: true } }));
    updateLocalStorage('admin', admin.id);
  };

  const resolveUser = (user: User) => {
    setData((prev) => ({ ...prev, ...modelUserData(user), isLogged: true }));
    updateLocalStorage('user', user.id);
  };

  const callback = (resp: FormattedSignInResp) => {
    const errors = resp.errors || [];
    handleOnSettled<FormattedSignInResp>(
      resp,
      (values) => {
        const { type, data } = values as unknown as FormattedSignInData;
        if (type === 'admin') resolveAdmin(data);
        if (type === 'user') resolveUser(data);
        successHandler();
      },
      () =>
        handleErrors({ root: errors?.[0] || '', ...errors }, (key, message) =>
          form.setError(key as keyof z.infer<typeof formSchema>, { message }),
        ),
    );
  };

  const { mutate, isLoading } = useSignIn({ callback });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    mutate(values);
  };

  return {
    form,
    onSubmit,
    locked: isLoading,
  };
};
