import { Loader } from '@components/loader';
import { DashboardProps } from './types';

export const Dashboard = ({ children, isLoading }: DashboardProps) => (
  <section className="relative grid h-fit w-full grid-cols-1">
    {isLoading ? (
      <header className="rounded-lg bg-white px-6 py-4">
        <Loader local />
      </header>
    ) : (
      children
    )}
  </section>
);
