import { AppUser } from '@contexts/app/types';
import { User } from '@root/globalTypes';

export const modelUserData = ({ id, firstName, lastName, role, tenant }: User): AppUser => ({
  id,
  firstName: firstName || '',
  lastName: lastName || '',
  role,
  isManager: [
    'administrator',
    'superintendent',
    'project_coordinator',
    'general_superintendent',
    'read_only',
  ].includes(role),
  isForeman: role === 'foreman',
  isCadTechnician: role === 'cad_technician',
  tenant,
});
