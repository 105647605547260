import { TaskFormAppearance, TaskFormValues } from './types';

export const defaultAppearance: TaskFormAppearance = {
  type: '',
  color: '',
  icon: '',
  isPrefix: false,
  prefix: {
    color: '',
    icon: '',
    text: '',
  },
};

export const defaultValues: TaskFormValues = {
  name: '',
  taskType: '',
  important: false,
  dateConfirmation: '',
  foremanSpeciality: '',
  scheduleType: '',
  count: '',
  unit: '',
  state: '',
  taskConfirmation: '',
  importantSchedule: false,
  concurrentSchedules: false,
  nonObligatory: '',
  removable: false,
  scheduleDependentTaskId: '',
  confirmationDependentSubtaskId: '',
  readyToSchedule: {
    enabled: false,
    rules: [{ optionId: '', stageName: '', taskName: '', status: '' }],
  },
  manuallyAdded: '',
  multiplicable: false,
  appearance: defaultAppearance,
};
