import { useQueryParams } from '@hooks/queryParams';
import { useElementMeasures } from '@hooks/useElementMeasures';
import { uuidRegEx } from '@utils/consts';
import { useCallback, useEffect } from 'react';

export const useDashboardItems = () => {
  const headerHeight = useElementMeasures('header').height;
  const stickyHeaderHeight = useElementMeasures('dashboard-sticky-header').height;

  const {
    params: { focus, ...params },
    setParams,
  } = useQueryParams();

  const openedItems = (params.open || []).filter((el) => uuidRegEx.test(el));

  const isOpen = (id: string) => Boolean(openedItems.includes(id));

  const scrollToItem = useCallback(
    (id: string) => {
      const item = document.querySelector(`#dashboard-item-${id}`) as HTMLElement;
      const marginTop = 8;

      scrollTo({
        top:
          window.scrollY +
          item?.getBoundingClientRect().top -
          headerHeight -
          stickyHeaderHeight -
          marginTop,
        behavior: 'smooth',
      });
    },
    [headerHeight, stickyHeaderHeight],
  );

  const open = (id: string, withScroll?: boolean) => {
    if (!isOpen(id)) {
      const itemsWithNewIncluded = [...(openedItems || []), id];
      setParams(
        { open: itemsWithNewIncluded, focus: id },
        { preventScrollReset: true, disableSaveFilters: true },
      );
    }
    if (withScroll) scrollToItem(id);
  };

  const close = (id: string) => {
    const itemsWithCurrentExcluded = openedItems.filter((el) => el !== id);
    setParams(
      {
        open: itemsWithCurrentExcluded.length ? itemsWithCurrentExcluded : undefined,
        focus: undefined,
      },
      { preventScrollReset: true, disableSaveFilters: true },
    );
  };

  const toggle = (id: string) => {
    if (isOpen(id)) close(id);
    else open(id);
  };

  useEffect(() => {
    if (!focus) return;
    scrollToItem(focus);
  }, [focus, scrollToItem]);

  return { open, toggle, isOpen };
};
