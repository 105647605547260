export const badgeVariants = {
  variant: {
    default: 'border-transparent bg-neutral-900 text-neutral-50 hover:bg-neutral-900/80',
    outline: 'text-neutral-950',
    gray: 'bg-neutral-100 text-neutral-600',
    active: 'bg-success-100 text-success-700 border-success-100',
    pending: 'bg-info-100 text-info-700 border-info-100',
    deactivated: 'bg-danger-100 text-danger-500 border-danger-100',
    layout: 'bg-task-purple-100 text-task-purple-700 border-task-purple-100',
    footing: 'bg-task-blue-100 text-task-blue-700 border-task-blue-100',
    walls: 'bg-task-orange-100 text-task-orange-700 border-task-orange-100',
    pump: 'bg-task-yellow-100 text-task-yellow-700 border-task-yellow-100',
    stripout: 'bg-task-aqua-100 text-task-aqua-700 border-task-aqua-100',
    crane: 'bg-task-green-100 text-task-green-700 border-task-green-100',
    utilityDriver: 'bg-task-pink-100 text-task-pink-700 border-task-pink-100',
    customerService: 'bg-task-cyan-100 text-task-cyan-700 border-task-cyan-100',
  },
};
