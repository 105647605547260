import { useMutation } from '@tanstack/react-query';
import { FiltersReq, FiltersRespSuccess } from './types';
import { queryFetch } from '@services/api';
import { useAppContext } from '@contexts/app';
import { usePanel } from '@hooks/usePanel';

export const useUpdateFilters = () => {
  const {
    id: userId,
    admin: { data: adminData },
    setData,
  } = useAppContext();

  const { panel } = usePanel();

  const id = userId || adminData?.id;

  const { mutate, isPending } = useMutation({
    mutationFn: ({ data }: FiltersReq): Promise<FiltersRespSuccess> =>
      queryFetch({
        url: [panel ? 'admins' : 'users', id, 'update_filters'].join('/'),
        panel,
        data: { [panel ? 'admin' : 'user']: { filters: data } },
        opt: { method: 'PUT' },
      }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ data, success }) => {
      if (!success || !data || !setData) return;
      setData((prev) => ({ ...prev, filters: data }));
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
