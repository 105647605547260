import { useQuery } from '@tanstack/react-query';
import { queryFetch } from '@services/api';
import { queryKeys } from '@utils/consts';
import { ApiError } from '@root/globalTypes';
import { useAppContext } from '@contexts/app';
import { PanelDropdownRespSuccess } from '../types';
import { usePanel } from '@hooks/usePanel';

export const usePanelRolesDropdown = () => {
  const {
    admin: {
      isLogged,
      data: { role },
    },
  } = useAppContext();

  const { panel } = usePanel();

  const { data, error, isFetching } = useQuery<PanelDropdownRespSuccess, ApiError>({
    queryKey: queryKeys.dropdowns.panel.roles,
    queryFn: () => queryFetch({ url: 'dropdowns/roles', panel }),
    placeholderData: (previousData) => previousData as PanelDropdownRespSuccess,
    enabled: isLogged && role?.value === 'administrator' && panel,
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
