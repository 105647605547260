import { DefaultActive, SchedulerData } from './types';
import { getDividedHours, getFirstHour, getHours } from '@components/scheduler/grid/helpers';
import { Minutes } from './types';
import { defaultAppearance } from '@hooks/forms/panel/tenants/workflows/task/constants';

export const SEGMENT_LENGTH: Minutes = 15;
export const SEGMENTS_PER_HOUR: number = 60 / SEGMENT_LENGTH;
export const SEGMENT_WIDTH = { width: `calc(100%/${SEGMENTS_PER_HOUR})` };
export const DEFAULT_NEW_SCHEDULE_LENGTH = SEGMENT_LENGTH;

export const START_HOUR: number = 6;
export const HOURS_COUNT: number = 13;
export const DIVIDED_HOUR = [...new Array<undefined>(SEGMENTS_PER_HOUR)];

export const defaultActive: DefaultActive = {
  schedule: {
    id: '',
    foreman: { id: '' },
    startsAt: '',
    endsAt: '',
    status: '',
    task: {
      id: '',
      name: '',
      job: {
        id: '',
        name: '',
        address: '',
      },
      stage: {
        id: '',
        name: '',
      },
      appearance: defaultAppearance,
    },
  },
  newValues: {},
  fresh: false,
  mode: 'move',
  initialX: 0,
  cursor: 'cursor-auto',
  x: 0,
  y: 0,
};

export const initialState: SchedulerData = {
  date: getFirstHour(new Date()),
  data: {
    raw: {
      foremen: [],
      withoutTeam: [],
    },
    grouped: {
      foremen: [],
      withoutTeam: [],
    },
  },
  hours: getDividedHours(getHours(new Date())),
  modal: false,
  inventory: false,
  active: defaultActive,
};
