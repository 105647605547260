import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { SetError, handleOnSettled, useHandleFormErrors } from '@utils/formHelpers';
import { DocumentResp, useCreateDocument } from '@services/documents/create';
import { useUpdateDocument } from '@services/documents/update';
import { DocumentsQueryType } from '@services/documents';
import { useTranslation } from 'react-i18next';

interface UploadFileFormValues {
  subtaskId?: string;
  name?: string;
}

export const useUploadFileForm = ({
  id,
  jobId,
  files,
  queryType,
  successHandler: successHandler,
}: {
  id?: string;
  successHandler: () => void;
  files: FileList | null;
  queryType: DocumentsQueryType;
  jobId?: string;
}) => {
  const handleFormErrors = useHandleFormErrors();
  const { t } = useTranslation('app');

  const formSchema = z.object({
    subtaskId: z.string().min(1, t('forms.documentType.required')),
    name: z.custom<string>((val) => {
      const select = document.querySelector('[name="subtaskIdSelect"]') as HTMLSelectElement;
      if (queryType === 'foreman' || select?.value === 'other') return val;
      return true;
    }, t('forms.documentName.required')),
  });

  const defaultValues: UploadFileFormValues = {
    subtaskId: '',
    name: '',
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const onSettled = (resp: DocumentResp) => {
    const handleError: SetError = (name, { type, message }) => {
      form.setError(name as keyof z.infer<typeof formSchema>, {
        type,
        message,
      });
    };

    const keys = [
      'min_size_error',
      'max_size_error',
      'cannot_be_blank',
      'cannot_be_empty',
      'invalid',
    ];
    let error: object = {};
    const errors = resp?.errors as { base: string };

    keys.forEach((key) => {
      if (errors?.base.includes(key)) error = { base: key };
    });

    handleOnSettled<DocumentResp>(resp, successHandler, () =>
      handleFormErrors(handleError, Object.keys(defaultValues), error, true),
    );
  };

  const create = useCreateDocument({ callback: onSettled, queryType });
  const update = useUpdateDocument({ callback: onSettled, queryType });

  const onSubmit = async ({ name, subtaskId }: z.infer<typeof formSchema>) => {
    if (!jobId) return;
    const formData = new FormData();

    if (id) {
      if (files) formData.append('document[file]', files[0]);
      formData.append('document[name]', name || 'file');
    } else if (files) {
      formData.append('document[job_id]', jobId);
      if (subtaskId === 'other') {
        Array.from(files).forEach((file) => {
          formData.append(`document[files][][file]`, file);
          formData.append(`document[files][][name]`, name || '');
        });
      } else {
        formData.append('document[subtask_id]', subtaskId || '');
        Array.from(files).forEach((file) => {
          formData.append(`document[files][][file]`, file);
        });
      }
    }
    if (id) update.mutate({ id, body: formData });
    else create.mutate(formData);
  };

  return {
    form,
    onSubmit,
    locked: create.isLoading || update.isLoading,
  };
};
