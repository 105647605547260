import { adminsRoutes } from './admins/routes';
import { signUpRoutes } from '@pages/signIn/routes';
import { tenantsRoutes } from './tenants/routes';
import { panelRoute as route } from '@utils/consts';

const routes = [
  {
    path: route.index,
    children: [
      {
        path: '',
        lazy: async () => {
          const { Panel } = await import('@pages/panel');
          return { Component: () => <Panel /> };
        },
      },
      ...signUpRoutes,
      ...adminsRoutes,
      ...tenantsRoutes,
    ],
  },
];

export { routes as panelRoutes };
