import { Alert, Issue, IssueVariant } from '@root/globalTypes';

export const getVariant = ({ resolved, critical }: Issue): IssueVariant => {
  if (resolved) return 'resolved';
  if (critical) return 'critical';
  return 'regular';
};

export const getImportantIssueVariant = (array: Issue[]): IssueVariant => {
  const variants = array.map((el) => getVariant(el));
  if (variants.includes('critical')) return 'critical';
  if (variants.includes('regular')) return 'regular';
  return 'resolved';
};

export const getIssuesFromAlerts = (data: Alert[]): Alert[] =>
  data.filter((el) => el.alertType === 'issue');
