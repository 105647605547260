import { queryFetch } from '@services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { appRoutes, foremanRoute, queryKeys } from '@utils/consts';
import { NoteDefaultReq, NoteResp } from './types';
import { useLocation } from 'react-router-dom';

export const useRemoveNote = ({ callback }: { callback: (data: NoteResp) => void }) => {
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ id }: NoteDefaultReq): Promise<NoteResp> =>
      queryFetch({
        url: `notes/${id}`,
        opt: { method: 'DELETE' },
      }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (!success) return;
      await queryClient.refetchQueries({ queryKey: queryKeys.job });
      if (pathname === `/${appRoutes.jobs}`) {
        await queryClient.refetchQueries({ queryKey: queryKeys.jobs });
      }
      if (!pathname.includes(foremanRoute.questionnaires)) return;
      await queryClient.refetchQueries({ queryKey: queryKeys.questionnaire });
    },
    onSettled: (data) => {
      if (data) callback(data);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
