import { SvgConsumer } from '@components/icons/svgConsumer';
import { Input, InputProps } from '../input';
import { forwardRef, useState } from 'react';
import { cn } from '@utils/cn';

export const PasswordInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <Input
      ref={ref}
      type={visible ? 'text' : 'password'}
      {...props}
      suffixElement={
        <button
          className={cn([
            'flex h-5 w-5 items-center justify-center text-neutral-400 hover:text-neutral-600',
            visible && 'pt-[2px]',
          ])}
          type="button"
          onClick={() => setVisible(!visible)}>
          <SvgConsumer className="text-m" id={!visible ? 'visible' : 'invisible'} />
        </button>
      }
    />
  );
});
