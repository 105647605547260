import { cn } from '@utils/cn';
import { DashboardItemProps } from './types';
import { SvgConsumer } from '@components/icons/svgConsumer';
import { DashboardItemActionButton } from './button';
import { useDashboardItems } from '@hooks/dashboard/items';
import { useElementMeasures } from '@hooks/useElementMeasures';
import { getElementStyle } from '@utils/getElementStyle';

export const DashboardItem = ({ id, title, actions, body, children }: DashboardItemProps) => {
  const { toggle, isOpen } = useDashboardItems();
  const headerHeight = useElementMeasures('header').height;
  const stickyHeaderHeight = useElementMeasures('dashboard-sticky-header').height;
  const gap = getElementStyle('dashboard-layout', 'gap');

  return (
    <>
      <header
        id={`dashboard-item-${id}`}
        onClick={() => toggle(id)}
        style={{
          top: headerHeight + stickyHeaderHeight + gap,
        }}
        className={cn([
          'z-10 col-start-1 col-end-5 mt-2 flex cursor-pointer select-none justify-between border-neutral-200 bg-white px-6 py-4 first-of-type:mt-0',
          isOpen(id) ? 'sticky z-20 rounded-t-lg border-b-2' : 'rounded-lg',
        ])}>
        <div className="flex items-center gap-2.5">
          {typeof title === 'string' ? (
            <h2 className="text-lg font-bold text-neutral-800">{title}</h2>
          ) : (
            title
          )}
        </div>
        <div className="flex items-center gap-4">
          {actions}
          <DashboardItemActionButton onClick={() => toggle(id)}>
            <SvgConsumer
              id="expand"
              className={cn('shrink-0 text-sm', isOpen(id) && 'rotate-180')}
            />
          </DashboardItemActionButton>
        </div>
      </header>
      {isOpen(id) && (
        <>
          {body}
          {children}
        </>
      )}
    </>
  );
};
