/* eslint-disable immutable/no-mutation */

import { isPanel } from '@hooks/usePanel';
import { appRoutes } from '@utils/consts';
import { parseRoute } from '@utils/i18n';
import { localStore } from '@utils/localStore';
import { formatBody, formatResponse } from '@utils/textHelpers';

export type ResponseError = Error & {
  info: Record<string, unknown>;
  status: number;
};

const saveUserAuthParams = (res: Response, panel?: boolean, impersonate?: boolean) => {
  const key = `app-${panel && !impersonate ? 'admin' : 'user'}`;
  if (!res.headers.has('Access-Token')) return;
  const auth: Record<string, string | null> = {};
  const oldData = localStore.getItem(key);
  let cache: Record<string, string> = {};
  if (oldData && typeof oldData === 'string') cache = JSON.parse(oldData) as Record<string, string>;
  if (res.headers.has('Access-Token')) {
    auth['Access-Token'] = res.headers.get('Access-Token') || cache['Access-Token'];
  }
  if (res.headers.has('Client')) auth.Client = res.headers.get('Client') || cache.Client;
  if (res.headers.has('Uid')) auth.Uid = res.headers.get('Uid') || cache.Uid;
  if (res.headers.has('Expiry')) {
    auth.Expiry = res.headers.get('Expiry')?.toString() || cache.Expiry;
  }
  localStore.updateItem(key, auth, parseInt(auth.Expiry || cache.Expiry, 10));
};

const getUserAuthParams = (panel?: boolean) => {
  const key = `app-${panel ? 'admin' : 'user'}`;
  const params = new URLSearchParams(window.location.search);
  const authHeaders: HeadersInit = {};
  if (params.has('access-token')) {
    authHeaders['Access-Token'] = params.get('Access-Token') || '';
    authHeaders.Client = params.get('Client') || '';
    authHeaders.Uid = params.get('Uid') || '';
  } else {
    const savedData = localStore.getItem(key);
    let cache: Record<string, string> = {};
    if (savedData) {
      if (typeof savedData === 'string') cache = JSON.parse(savedData) as Record<string, string>;
      authHeaders['Access-Token'] = cache['Access-Token'];
    }
    authHeaders.Client = cache.Client;
    authHeaders.Uid = cache.Uid;
    authHeaders.Expiry = cache.Expiry;
  }
  return authHeaders;
};

const getUrl = (mock: boolean, panel: boolean) => {
  if (mock) return import.meta.env.VITE_MOCK_URL as string;
  if (panel) return import.meta.env.VITE_PANEL_API_URL as string;
  return import.meta.env.VITE_API_URL as string;
};

export const queryFetch = async <T>({
  url,
  data,
  opt,
  mock = false,
  auth = true,
  panel = false,
  impersonate = false,
  formData,
  blob,
}: {
  url: string;
  data?: Record<string, unknown>;
  opt?: RequestInit;
  mock?: boolean;
  auth?: boolean;
  panel?: boolean;
  impersonate?: boolean;
  formData?: boolean;
  blob?: boolean;
}): Promise<T> => {
  const createOptions = () => {
    const options: RequestInit = mock
      ? {}
      : {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Cache-Control': 'no-store',
          },
          cache: 'reload',
        };
    options.method = opt?.method || 'GET';

    if (!formData) options.headers = { ...options.headers, 'Content-Type': 'application/json' };
    if (data) options.body = JSON.stringify(formatBody(data));
    if (opt?.body) options.body = opt.body;
    if (auth && !mock) {
      options.headers = {
        ...options.headers,
        ...getUserAuthParams(panel),
      };
    }
    return options;
  };

  const api = getUrl(mock, panel);
  const resp = await fetch([api, url].join('/'), createOptions());

  if (resp.status === 401 && !url.includes('sign_in')) {
    localStore.removeItem('app-user');
    localStore.removeItem('app-admin');
    window.location.pathname = parseRoute(isPanel() ? `/${appRoutes.panel}` : appRoutes.index);
  }

  saveUserAuthParams(resp.clone(), panel, impersonate);

  if (blob) return resp as T;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return formatResponse(await resp.json());
};
