import { Avatar } from '@components/avatar';
import { PushNotifications } from '@components/permisions/pushNotifications';
import { PushTest } from '@components/permisions/test';
import { UpdateSW } from '@components/serviceWorker/update';
import { Button } from '@components/ui/button';
import { NavMenu } from '@components/ui/navigationMenu/navigationMenu';
import { useAppContext } from '@contexts/app';
import { cn } from '@utils/cn';
import { appRoutes, userAccessMap } from '@utils/consts';
import { parseRoute } from '@utils/i18n';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { NotificationsDialog } from './notificationsDialog';
import { useGetNotifications } from '@services/notifications';
import { getInitials, kebabToCamelCase, snakeToCamelCase } from '@utils/textHelpers';
import { SvgConsumer } from '@components/icons/svgConsumer';
import { getEnvType } from '@utils/getEnvType';
import { Badge } from '@components/ui/badge';
import { version } from '../../../package.json';
import { Tab } from '@root/globalTypes';
import { icons } from './constants';
import { NavbarButton } from './button';
import { NavbarTooltip } from './tooltip';
import { usePanel } from '@hooks/usePanel';
import { useLogout } from '@hooks/useLogout';
import { ImpersonateBanner } from '@components/ui/impersonateBanner';
import { localStore } from '@utils/localStore';
import { Loader } from '@components/loader';

export const Navbar = () => {
  const {
    isLogged,
    isManager,
    isForeman,
    setData,
    role,
    firstName,
    lastName,
    onDeckError,
    hotSeatError,
    tenant,
    admin: { data: adminData, isLogged: isAdminLogged },
  } = useAppContext();

  const admin = isAdminLogged || Boolean(localStore.getItem('app-admin'));
  const user = isLogged || Boolean(localStore.getItem('app-user'));
  const impersonate = Boolean(localStore.getItem('app-impersonate'));

  const { pathname } = useLocation();
  const { panel, parseUrl } = usePanel();
  const { t } = useTranslation('app');
  const envType = getEnvType();
  const [notificationsDialog, setNotificationsDialog] = useState(false);
  const { data: notifications } = useGetNotifications();
  const haveNotifications = Boolean(notifications?.records?.length);

  const logout = useLogout();

  const navLinkClasses = (isActive: boolean, error: boolean = false) =>
    cn(
      'hover:text-primary-500 relative block',
      isActive
        ? 'text-primary-500 after:h-[3px] after:bg-primary-500 after:absolute after:left-0 after:right-0 after:bottom-0'
        : 'text-neutral-400',
      error &&
        'before:block before:absolute before:bottom-1 before:right-1 before:h-3 before:w-3 before:rounded-full before:bg-danger-400',
    );

  const getHomeLink = () => {
    if (role === 'foreman') return `/${parseRoute(appRoutes.foremanSchedules)}`;
    return `/${parseRoute(parseUrl(appRoutes.index))}`;
  };

  const tabError = (path: Tab) => {
    if (path === 'hotSeat' && hotSeatError) return true;
    if (path === 'onDeck' && onDeckError) return true;
    return false;
  };

  const generateLinks = () => {
    if (!role && !adminData.menuAccess) return <Loader local />;
    const routes =
      !user && admin ? adminData.menuAccess : userAccessMap[snakeToCamelCase(role || '')];
    return (
      <ul className="absolute left-1/2 flex grow -translate-x-1/2 justify-center gap-4">
        {routes?.map((route) => {
          const tab = kebabToCamelCase(route) as Tab;
          return (
            <NavbarTooltip key={route} name={tab}>
              <li>
                <NavLink
                  onClick={(e) => {
                    if (pathname !== `/${parseRoute(route)}`) return;
                    e.preventDefault();
                  }}
                  to={`/${parseRoute(parseUrl(route))}`}
                  className={({ isActive }) => navLinkClasses(isActive, tabError(tab))}>
                  {icons[tab]}
                </NavLink>
              </li>
            </NavbarTooltip>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="header sticky left-0 right-0 top-0 z-[60] bg-white shadow-sm">
      <ImpersonateBanner />
      <nav className="container relative flex h-12 items-center gap-5 px-4 py-1">
        <div className="absolute left-4 top-1/2 flex -translate-y-1/2 items-center gap-4">
          <Link
            className="flex select-none items-center gap-1.5 font-semibold leading-none"
            to={getHomeLink()}>
            <Avatar
              className="mr-1 h-6 w-6 bg-primary-500"
              name={getInitials(tenant?.companyName || 'retti')}
              hideTooltip
            />
            <span>{(tenant?.companyName || 'retti').toLocaleUpperCase()}</span>
            {panel ? <span className="text-primary-500">ADMIN</span> : null}
          </Link>
          {envType !== 'prod' && (
            <Badge variant={envType === 'stage' ? 'footing' : 'active'}>
              {envType} v{version}
            </Badge>
          )}
        </div>
        {(user || admin) && (
          <>
            {generateLinks()}
            <div
              className={`absolute right-4 top-1/2 flex shrink-0 -translate-y-1/2 items-center justify-end gap-2 ${
                !isManager ? 'ms-auto' : ''
              }`}>
              {!isForeman && (
                <NavbarTooltip name="tutorial">
                  <NavbarButton
                    onClick={() => setData((prev) => ({ ...prev, isTutorial: !prev.isTutorial }))}>
                    <SvgConsumer className="shrink-0 text-heading-s" id="tooltip" />
                  </NavbarButton>
                </NavbarTooltip>
              )}
              {isForeman && (
                <Link to={parseRoute(appRoutes.foremanArchive)}>
                  <NavbarButton
                    disabled={!haveNotifications}
                    className={cn(
                      pathname.includes(appRoutes.foremanArchive) && 'text-primary-500',
                    )}>
                    {Boolean(notifications?.missingOptionalSubtasks) && (
                      <span className="absolute bottom-0.5 right-1 flex h-5 w-5 items-center justify-center rounded-full bg-danger-400 text-white">
                        {notifications?.missingOptionalSubtasks}
                      </span>
                    )}
                    <SvgConsumer className="text-heading-xs" id="archive" />
                  </NavbarButton>
                </Link>
              )}
              {!admin && (
                <NavbarTooltip name="notifications">
                  <NavbarButton
                    disabled={!haveNotifications}
                    onClick={() => setNotificationsDialog(!notificationsDialog)}
                    className={cn(
                      Boolean(notifications?.unreadNotifications) &&
                        'after:absolute after:bottom-1 after:right-6 after:block after:h-2.5 after:w-2.5 after:rounded-full after:bg-danger-400',
                    )}>
                    <SvgConsumer className="text-heading-m" id="notifications" />
                  </NavbarButton>
                </NavbarTooltip>
              )}
              <NavMenu
                disabled={impersonate}
                trigger={
                  <Avatar
                    name={user ? [firstName, lastName].join(' ') : adminData?.name}
                    hideTooltip
                  />
                }>
                <Button variant="ghost" onClick={() => logout(panel ? 'admin' : 'user')} size="sm">
                  {t('navigation.logout')}
                </Button>
                <Link to={parseRoute(appRoutes.passwordReset)}>
                  <Button className="whitespace-nowrap" variant="ghost" size="sm">
                    {t('navigation.changePassword')}
                  </Button>
                </Link>
                <div>{import.meta.env.VITE_PUSH_TEST ? <PushTest /> : null}</div>
                <div>
                  {import.meta.env.VITE_SENTRY_TEST ? (
                    <button
                      type="button"
                      onClick={() => {
                        throw new Error('Sentry Test Error');
                      }}>
                      Break the world
                    </button>
                  ) : null}
                </div>
              </NavMenu>
            </div>
          </>
        )}
        {!user && !admin && (
          <Link to={`/${parseRoute(parseUrl(appRoutes.index))}`} className="ms-auto">
            <strong className="font-inter text-s text-neutral-400 hover:text-primary-500">
              {t('navigation.login')}
            </strong>
          </Link>
        )}
      </nav>
      <UpdateSW />
      {user && <PushNotifications />}
      <NotificationsDialog
        open={notificationsDialog}
        setOpen={setNotificationsDialog}
        data={notifications}
      />
    </div>
  );
};
