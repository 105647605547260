import { useQuery } from '@tanstack/react-query';
import { queryKeys, uuidRegEx } from '@utils/consts';
import { queryFetch } from '@services/api';
import { ApiError } from '@root/globalTypes';
import { AdminRespSuccess } from './types';

export const useGetAdmin = (id: string) => {
  const { data, error, isFetching } = useQuery<AdminRespSuccess, ApiError>({
    queryKey: [...queryKeys.admin, id],
    queryFn: () => queryFetch({ url: `admins/${id}`, panel: true }),
    enabled: Boolean(id && uuidRegEx.test(id)),
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
