import { useAppContext } from '@contexts/app';
import { localStore } from '@utils/localStore';
import { useEffect } from 'react';
import { getSlug, isSlugCorrect, updateSlug } from './helpers';
import { useQueryParams } from '@hooks/queryParams';
import { User } from '@root/globalTypes';
import { usePanel } from '@hooks/usePanel';
import { useLocation } from 'react-router-dom';
import { appRoutes } from '@utils/consts';

export const useSlug = () => {
  const {
    tenant,
    isForeman,
    admin: { isLogged: isAdminLogged },
  } = useAppContext();

  const {
    params: { auth },
    setParams,
  } = useQueryParams();

  const { panel } = usePanel();
  const { pathname } = useLocation();

  const slug = tenant?.slug || getSlug();
  const impersonate = Boolean(localStore.getItem('app-impersonate'));

  useEffect(() => {
    if (
      isAdminLogged ||
      impersonate ||
      panel ||
      !slug ||
      isForeman ||
      pathname.includes(appRoutes.usersAcceptInvitation)
    )
      return;
    if (isSlugCorrect(slug)) return;
    const user = localStore.getItem('app-user');
    setParams({ auth: String(user) }, { disableSaveFilters: true });
    updateSlug(slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, pathname]);

  useEffect(() => {
    if (!auth) return;
    const user = JSON.parse(auth) as User;
    if (!user) return;
    localStore.setItem('app-user', JSON.stringify(user));
    setParams({ auth: '' }, { disableSaveFilters: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);
};
