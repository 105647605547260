import { Dashboard } from '@components/dashboard';
import { DashboardItem } from '@components/dashboard/item';
import { JobAlert } from '@components/job/workProgress/alert';
import { JobDate } from '@components/job/workProgress/date';
import { getJobStatus, isWithoutSchedule, sortByProp } from '@components/job/workProgress/helpers';
import { JobStageMenu } from '@components/job/workProgress/stage/menu';
import { JobTask } from '@components/job/workProgress/task';
import { useJobContext } from '@contexts/job';
import { sortByDate } from '@pages/hotSeat/utils';
import { JobStage as JobStageType, JobTaskData, JobTask as JobTaskType } from '@root/globalTypes';
import { parseISO } from 'date-fns';
import { Fragment } from 'react';

export const WorkProgress = () => {
  const { stages, isLoading } = useJobContext();

  return (
    <Dashboard isLoading={isLoading}>
      {sortByProp<JobStageType>(stages, 'position', 'asc').map((stage) => (
        <DashboardItem
          key={`dashboard-item-${stage.id}`}
          id={stage.id}
          title={
            <div className="flex items-center gap-2.5">
              <h2 className="text-lg font-bold text-neutral-800">{stage.name}</h2>
              <JobAlert status={getJobStatus(stage)} alerts={stage.alerts} />
            </div>
          }
          actions={<JobStageMenu jobId={stage.jobId} multi={stage.multiplicableTasks} />}
          body={
            <>
              {sortByProp<JobTaskType>(
                stage.tasks.find((el) => isWithoutSchedule(el))?.tasks || [],
                'position',
                'asc',
              ).map((task) => (
                <JobTask key={task.id} data={task} stage={stage} />
              ))}
              {sortByDate<JobTaskData>(
                stage.tasks.filter((el) => !isWithoutSchedule(el)),
                'date',
                'asc',
              ).map((el, index) => (
                <Fragment key={`job-stage-day-${index}`}>
                  <JobDate date={parseISO(el.date)} alerts={el.dayAlerts} />
                  {sortByProp<JobTaskType>(el.tasks, 'position', 'asc').map((task) => (
                    <JobTask key={task.id} data={task} date={el.date} stage={stage} />
                  ))}
                </Fragment>
              ))}
            </>
          }
        />
      ))}
    </Dashboard>
  );
};
